const LocationReviewsData = [
    {
        "date": "2024-03-13",
        "review_rate": 4.8,
        "customer_name": "Eric Wood",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Eric M.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-10-08",
        "review_rate": 5.0,
        "customer_name": "Cassandra Ross",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Charles N.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-06-27",
        "review_rate": 4.9,
        "customer_name": "Victoria Rodriguez",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Dana G.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-12-22",
        "review_rate": 4.8,
        "customer_name": "Michael Palmer",
        "service_type": "Garage Door Opener",
        "tech_name": "Ricky W.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-07-18",
        "review_rate": 4.9,
        "customer_name": "Vanessa Mitchell",
        "service_type": "Garage Door Opener",
        "tech_name": "David R.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-11-25",
        "review_rate": 4.9,
        "customer_name": "Juan Anderson",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Corey M.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-06-28",
        "review_rate": 5.0,
        "customer_name": "Michael Miller",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "William C.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-10-28",
        "review_rate": 4.9,
        "customer_name": "Angela Garrett DDS",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Amy B.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-05-20",
        "review_rate": 5.0,
        "customer_name": "Jenna Perez",
        "service_type": "Garage Door Installation",
        "tech_name": "Pamela B.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-03-31",
        "review_rate": 5.0,
        "customer_name": "Blake Holt",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Daniel P.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-01-25",
        "review_rate": 4.9,
        "customer_name": "Erika Johnson",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Edgar F.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-05-25",
        "review_rate": 4.8,
        "customer_name": "Amy Parker",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Aaron B.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-01-20",
        "review_rate": 4.8,
        "customer_name": "Jennifer Lopez",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Robert B.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-04-13",
        "review_rate": 4.9,
        "customer_name": "Faith Castillo",
        "service_type": "Garage Door Insulation",
        "tech_name": "Alicia H.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-03-24",
        "review_rate": 4.8,
        "customer_name": "Sharon Benjamin",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Sandra C.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-10-07",
        "review_rate": 4.8,
        "customer_name": "Meagan Wilson",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Johnny S.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-03-11",
        "review_rate": 5.0,
        "customer_name": "Karen Lucas",
        "service_type": "Garage Door Repair",
        "tech_name": "Catherine A.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-07-07",
        "review_rate": 5.0,
        "customer_name": "Rebecca Carson",
        "service_type": "Garage Door Services",
        "tech_name": "Steven W.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-09-02",
        "review_rate": 4.9,
        "customer_name": "Luis Hudson",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Javier C.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-02-27",
        "review_rate": 4.9,
        "customer_name": "Elaine Harrison",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Laura S.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-02-19",
        "review_rate": 5.0,
        "customer_name": "Jennifer Wheeler",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Micheal C.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-08-11",
        "review_rate": 4.9,
        "customer_name": "Dwayne Cruz",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Michele J.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-01-04",
        "review_rate": 5.0,
        "customer_name": "Patricia Watson MD",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "James F.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-06-01",
        "review_rate": 5.0,
        "customer_name": "Joseph Campos",
        "service_type": "Garage Door Opener",
        "tech_name": "Steve E.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-04-21",
        "review_rate": 4.8,
        "customer_name": "Tyler Moon",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Brandon H.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-11-19",
        "review_rate": 4.9,
        "customer_name": "Robert Jackson",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Tanya E.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-01-25",
        "review_rate": 4.9,
        "customer_name": "Tristan Smith",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "David L.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-03-15",
        "review_rate": 4.8,
        "customer_name": "Michael Owens",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Bradley S.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-01-23",
        "review_rate": 4.9,
        "customer_name": "Susan Garcia",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Angela J.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-03-18",
        "review_rate": 5.0,
        "customer_name": "Angelica Lane",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Robert G.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-06-30",
        "review_rate": 4.9,
        "customer_name": "Timothy Moore",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Anita H.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-04-17",
        "review_rate": 4.9,
        "customer_name": "Bryan Gonzalez",
        "service_type": "Garage Door Off Track",
        "tech_name": "Brittany T.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-07-15",
        "review_rate": 4.9,
        "customer_name": "Michelle Montgomery",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Patrick B.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-11-16",
        "review_rate": 4.9,
        "customer_name": "Marie Jones",
        "service_type": "Garage Door Installation",
        "tech_name": "Madison C.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-11-08",
        "review_rate": 5.0,
        "customer_name": "Stephanie Page",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Audrey S.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-05-28",
        "review_rate": 4.9,
        "customer_name": "Alexander Wiggins",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Cynthia M.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-06-09",
        "review_rate": 5.0,
        "customer_name": "Brandon Black",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Jamie B.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-02-06",
        "review_rate": 4.8,
        "customer_name": "Mario Lester MD",
        "service_type": "Garage Door Installation",
        "tech_name": "Kari G.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-06-27",
        "review_rate": 4.8,
        "customer_name": "Andrew Edwards",
        "service_type": "Garage Door Repair",
        "tech_name": "Maria S.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-11-19",
        "review_rate": 4.9,
        "customer_name": "Heather Wilson",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Shelby J.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-12-23",
        "review_rate": 4.9,
        "customer_name": "Gail Williams",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Nancy W.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-01-02",
        "review_rate": 4.8,
        "customer_name": "Rachael Barnes",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Elizabeth L.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-07-09",
        "review_rate": 4.9,
        "customer_name": "Elizabeth Ball",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Phillip L.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-10-05",
        "review_rate": 4.9,
        "customer_name": "Vanessa Rodriguez DVM",
        "service_type": "Garage Door Installation",
        "tech_name": "Tonya K.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-05-11",
        "review_rate": 4.8,
        "customer_name": "Logan Martin",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Kent M.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-03-23",
        "review_rate": 4.8,
        "customer_name": "Richard Gutierrez",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Andres M.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-02-16",
        "review_rate": 4.9,
        "customer_name": "Craig Cooper",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Jessica M.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-12-14",
        "review_rate": 4.9,
        "customer_name": "Jessica Miller",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Michael M.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-07-16",
        "review_rate": 5.0,
        "customer_name": "Justin Rogers",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Sabrina T.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-01-10",
        "review_rate": 5.0,
        "customer_name": "Alicia Brown",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Amy B.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-09-24",
        "review_rate": 4.8,
        "customer_name": "Jasmine Powell",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Julia R.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-06-07",
        "review_rate": 4.8,
        "customer_name": "Kristi Gonzalez",
        "service_type": "Garage Door Services",
        "tech_name": "Megan S.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-09-04",
        "review_rate": 4.8,
        "customer_name": "Kim Phillips",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Marco A.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-06-25",
        "review_rate": 4.9,
        "customer_name": "Thomas Maldonado",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Susan T.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-01-17",
        "review_rate": 4.9,
        "customer_name": "Nicole Anderson",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "David S.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-07-14",
        "review_rate": 5.0,
        "customer_name": "Dave Mays",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Richard S.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-12-22",
        "review_rate": 4.9,
        "customer_name": "Jessica Doyle",
        "service_type": "Garage Door Services",
        "tech_name": "Manuel M.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-12-27",
        "review_rate": 4.9,
        "customer_name": "Daniel Jackson",
        "service_type": "Garage Door Opener",
        "tech_name": "Jean R.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-10-16",
        "review_rate": 4.9,
        "customer_name": "Jeffrey Miller",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Amanda P.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-04-06",
        "review_rate": 5.0,
        "customer_name": "James Powell",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Autumn H.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-06-19",
        "review_rate": 4.9,
        "customer_name": "Kelly Bennett",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Martha C.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-10-24",
        "review_rate": 4.9,
        "customer_name": "Gina Rodriguez",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Marco L.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-10-19",
        "review_rate": 4.8,
        "customer_name": "Kayla Gonzales",
        "service_type": "Garage Door Services",
        "tech_name": "Jamie N.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-03-27",
        "review_rate": 4.9,
        "customer_name": "Shane Wheeler",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Tanner J.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-06-29",
        "review_rate": 4.9,
        "customer_name": "Mr. Tanner Burns",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Joshua L.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-03-21",
        "review_rate": 4.9,
        "customer_name": "Michael Rios",
        "service_type": "Garage Door Opener",
        "tech_name": "Antonio P.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-02-05",
        "review_rate": 4.9,
        "customer_name": "Steven Long",
        "service_type": "Garage Door Installation",
        "tech_name": "Melissa M.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-11-15",
        "review_rate": 4.8,
        "customer_name": "Brandon Valdez",
        "service_type": "Garage Door Opener",
        "tech_name": "Michael W.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-05-18",
        "review_rate": 4.8,
        "customer_name": "Gregory Murillo",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Joseph C.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-11-29",
        "review_rate": 4.8,
        "customer_name": "Rachel Russell",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Mary L.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-03-25",
        "review_rate": 5.0,
        "customer_name": "Christine Curtis",
        "service_type": "Garage Door Off Track",
        "tech_name": "Courtney H.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-05-22",
        "review_rate": 5.0,
        "customer_name": "Tracy Crosby",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Alexis S.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-03-14",
        "review_rate": 4.9,
        "customer_name": "Alejandra Snyder",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Thomas C.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-12-27",
        "review_rate": 4.9,
        "customer_name": "Thomas Rodriguez",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Shelley F.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-02-10",
        "review_rate": 4.9,
        "customer_name": "Cynthia Butler",
        "service_type": "Garage Door Track Repair",
        "tech_name": "James R.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-12-08",
        "review_rate": 4.8,
        "customer_name": "Diana Johnson",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Megan G.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-06-01",
        "review_rate": 4.8,
        "customer_name": "Austin Chavez",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Anthony F.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-11-11",
        "review_rate": 5.0,
        "customer_name": "Ronald Bates",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Joe S.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-04-11",
        "review_rate": 4.8,
        "customer_name": "Jeremiah Lucas",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Michael W.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-10-22",
        "review_rate": 4.9,
        "customer_name": "Jeffery Webster",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Antonio T.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-12-09",
        "review_rate": 4.8,
        "customer_name": "David Davis Jr.",
        "service_type": "Garage Door Off Track",
        "tech_name": "Shawn G.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-02-22",
        "review_rate": 4.8,
        "customer_name": "Miranda Wilson",
        "service_type": "Garage Door Installation",
        "tech_name": "Megan M.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-02-28",
        "review_rate": 4.9,
        "customer_name": "Pamela Eaton",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Jenny G.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-09-29",
        "review_rate": 5.0,
        "customer_name": "Wayne Lucero",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Matthew R.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-04-21",
        "review_rate": 4.9,
        "customer_name": "Margaret Sawyer",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Regina B.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-10-18",
        "review_rate": 4.9,
        "customer_name": "Stephanie Hartman",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Amanda L.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-07-22",
        "review_rate": 5.0,
        "customer_name": "Harold Williams",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Ryan P.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-06-13",
        "review_rate": 4.9,
        "customer_name": "Eric Wheeler",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Brianna B.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-06-30",
        "review_rate": 5.0,
        "customer_name": "Charles Avila",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "George G.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-07-07",
        "review_rate": 4.8,
        "customer_name": "Nathan Rollins",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Alyssa J.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-03-08",
        "review_rate": 4.9,
        "customer_name": "Brandon Fischer",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Kelli S.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-12-05",
        "review_rate": 4.8,
        "customer_name": "Erik Hays",
        "service_type": "Garage Door Services",
        "tech_name": "Wanda H.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-08-22",
        "review_rate": 4.8,
        "customer_name": "David Wagner",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "William P.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-10-11",
        "review_rate": 4.9,
        "customer_name": "Brenda Deleon",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Susan A.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-11-16",
        "review_rate": 4.8,
        "customer_name": "Samuel Miller",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Annette B.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-02-14",
        "review_rate": 4.8,
        "customer_name": "Michael Hines",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Christopher W.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-08-01",
        "review_rate": 5.0,
        "customer_name": "Angela Mckenzie",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Bonnie G.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-03-08",
        "review_rate": 5.0,
        "customer_name": "Wanda Taylor",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Michelle K.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-08-03",
        "review_rate": 5.0,
        "customer_name": "Kelly Anderson",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Keith S.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-09-22",
        "review_rate": 4.8,
        "customer_name": "Caroline English",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "James A.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-05-18",
        "review_rate": 4.8,
        "customer_name": "Jerry Murray",
        "service_type": "Garage Door Installation",
        "tech_name": "Stephanie L.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-04-21",
        "review_rate": 5.0,
        "customer_name": "Jamie Martin",
        "service_type": "Garage Door Opener",
        "tech_name": "Amy A.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-09-23",
        "review_rate": 4.9,
        "customer_name": "Mrs. Jessica Brown",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Mallory M.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-04-23",
        "review_rate": 4.9,
        "customer_name": "Paul Martinez",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Angela C.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-01-08",
        "review_rate": 4.9,
        "customer_name": "Justin Bradley",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Christopher G.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-01-10",
        "review_rate": 4.9,
        "customer_name": "Mark Johnson",
        "service_type": "Garage Door Opener",
        "tech_name": "Steven R.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-10-24",
        "review_rate": 5.0,
        "customer_name": "Rose Howell",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Andrew H.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-04-15",
        "review_rate": 4.9,
        "customer_name": "Thomas Johnson",
        "service_type": "Garage Door Repair",
        "tech_name": "Colton C.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-02-27",
        "review_rate": 5.0,
        "customer_name": "Zachary Smith",
        "service_type": "Garage Door Services",
        "tech_name": "Valerie D.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-06-08",
        "review_rate": 4.9,
        "customer_name": "Tyler Barnes",
        "service_type": "Residential Garage Door Services",
        "tech_name": "David P.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-10-25",
        "review_rate": 4.9,
        "customer_name": "Destiny Salinas",
        "service_type": "Garage Door Opener",
        "tech_name": "Michelle P.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-02-23",
        "review_rate": 4.8,
        "customer_name": "Nicholas Kaufman",
        "service_type": "Garage Door Installation",
        "tech_name": "Charles H.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-11-14",
        "review_rate": 4.9,
        "customer_name": "Rachel Mcintosh",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Allison D.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-02-14",
        "review_rate": 4.9,
        "customer_name": "Christopher Nunez",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Ashley M.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-06-13",
        "review_rate": 5.0,
        "customer_name": "Jill Owen",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Jacqueline W.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-12-31",
        "review_rate": 5.0,
        "customer_name": "Tim Lopez",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Sarah J.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-06-29",
        "review_rate": 4.9,
        "customer_name": "John Bailey",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Monica H.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-05-07",
        "review_rate": 4.9,
        "customer_name": "Joshua Flores",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Lori W.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-04-13",
        "review_rate": 4.8,
        "customer_name": "Christian White",
        "service_type": "Garage Door Services",
        "tech_name": "Javier B.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-03-24",
        "review_rate": 4.9,
        "customer_name": "John Jackson",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Jesus P.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-08-05",
        "review_rate": 4.8,
        "customer_name": "Jason Ferrell",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Cynthia J.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-01-12",
        "review_rate": 4.9,
        "customer_name": "Stephen Duncan",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Danielle P.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-01-28",
        "review_rate": 4.9,
        "customer_name": "Amanda Cooke",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Derrick N.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-04-13",
        "review_rate": 4.9,
        "customer_name": "Laura Davis",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "David S.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-09-26",
        "review_rate": 4.9,
        "customer_name": "Jessica Price",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Debra B.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-08-08",
        "review_rate": 4.9,
        "customer_name": "Angie Morris",
        "service_type": "Garage Door Opener",
        "tech_name": "Stephen H.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-07-15",
        "review_rate": 4.9,
        "customer_name": "Eric Lewis",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Mike C.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-02-26",
        "review_rate": 5.0,
        "customer_name": "Courtney Erickson",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Amanda M.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-05-25",
        "review_rate": 4.9,
        "customer_name": "Matthew Quinn",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Elizabeth B.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-12-15",
        "review_rate": 4.9,
        "customer_name": "Cassidy Anderson",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "James L.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-12-19",
        "review_rate": 5.0,
        "customer_name": "Thomas Pennington",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Tiffany M.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-10-04",
        "review_rate": 4.8,
        "customer_name": "Miss Rebecca Clark",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Heather L.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-06-06",
        "review_rate": 4.8,
        "customer_name": "John Munoz",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Michael C.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-08-20",
        "review_rate": 4.9,
        "customer_name": "Matthew Fitzgerald",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Aimee M.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-05-22",
        "review_rate": 5.0,
        "customer_name": "Mark Allen",
        "service_type": "Garage Door Insulation",
        "tech_name": "Anthony L.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-09-06",
        "review_rate": 4.8,
        "customer_name": "Carrie Morales",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Joanne A.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-11-13",
        "review_rate": 4.9,
        "customer_name": "Brianna Myers",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Lori B.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-07-10",
        "review_rate": 4.9,
        "customer_name": "Robert Murray",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Michelle W.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-12-11",
        "review_rate": 4.8,
        "customer_name": "Ronnie Pugh",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "William P.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-06-21",
        "review_rate": 4.8,
        "customer_name": "Jennifer Davis",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Lisa H.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-02-20",
        "review_rate": 4.9,
        "customer_name": "Deborah Harper",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Kim B.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-04-25",
        "review_rate": 4.8,
        "customer_name": "Sandra Martin",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Lisa M.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-11-15",
        "review_rate": 4.9,
        "customer_name": "Jeffrey Pineda",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Annette M.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-04-15",
        "review_rate": 4.8,
        "customer_name": "Chelsey Williams",
        "service_type": "Garage Door Opener",
        "tech_name": "Katherine M.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-04-12",
        "review_rate": 5.0,
        "customer_name": "Valerie Clark",
        "service_type": "Garage Door Installation",
        "tech_name": "Edward R.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-12-03",
        "review_rate": 4.8,
        "customer_name": "Jordan Hart",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Rachel F.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-09-26",
        "review_rate": 4.9,
        "customer_name": "Richard Rivera",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Crystal G.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-04-04",
        "review_rate": 4.9,
        "customer_name": "Travis Glass",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Michael R.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-04-30",
        "review_rate": 5.0,
        "customer_name": "Marie Wade",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Robert F.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-01-23",
        "review_rate": 4.9,
        "customer_name": "Bryan Wells",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Adam D.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-07-04",
        "review_rate": 4.9,
        "customer_name": "Nicholas Martinez",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Jonathan S.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-02-08",
        "review_rate": 5.0,
        "customer_name": "Jessica Zavala",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Richard N.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-07-18",
        "review_rate": 4.9,
        "customer_name": "Brandy Burnett DDS",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Justin B.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-03-02",
        "review_rate": 5.0,
        "customer_name": "Adam Lee",
        "service_type": "Garage Door Services",
        "tech_name": "Jonathan R.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-06-18",
        "review_rate": 4.9,
        "customer_name": "Jeffrey Jones",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Zachary G.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-08-03",
        "review_rate": 5.0,
        "customer_name": "Janet Moyer",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Tamara S.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-02-09",
        "review_rate": 4.9,
        "customer_name": "James Mcdaniel",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Deanna V.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-02-26",
        "review_rate": 4.9,
        "customer_name": "Amy Martinez",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Sara E.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-08-27",
        "review_rate": 5.0,
        "customer_name": "Jesus Palmer",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Sophia M.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-09-22",
        "review_rate": 5.0,
        "customer_name": "Matthew Jones",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Tiffany M.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-10-03",
        "review_rate": 4.8,
        "customer_name": "Cody Peterson",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Cameron S.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-03-18",
        "review_rate": 5.0,
        "customer_name": "Jason Pena",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Brooke S.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-09-18",
        "review_rate": 4.8,
        "customer_name": "Olivia Garcia",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Howard W.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-05-18",
        "review_rate": 4.9,
        "customer_name": "Dylan Alvarez",
        "service_type": "Garage Door Off Track",
        "tech_name": "Christopher D.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-02-02",
        "review_rate": 5.0,
        "customer_name": "Jessica Pugh",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Karen C.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-02-08",
        "review_rate": 4.9,
        "customer_name": "James Lee",
        "service_type": "Garage Door Insulation",
        "tech_name": "Nicole S.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-02-25",
        "review_rate": 5.0,
        "customer_name": "Courtney Duncan",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Craig W.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-08-22",
        "review_rate": 4.8,
        "customer_name": "Stephanie Smith",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "April P.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-07-20",
        "review_rate": 5.0,
        "customer_name": "Elizabeth Harris",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Mark L.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-03-02",
        "review_rate": 5.0,
        "customer_name": "Andrew Mccoy",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Andrew W.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-09-17",
        "review_rate": 4.9,
        "customer_name": "Jeremy Johnson",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Ashley P.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-07-03",
        "review_rate": 4.9,
        "customer_name": "Elizabeth Hernandez",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Lisa B.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-03-24",
        "review_rate": 4.9,
        "customer_name": "Stephanie Smith",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Henry H.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-02-24",
        "review_rate": 4.8,
        "customer_name": "Diana West",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Amy F.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-07-10",
        "review_rate": 4.9,
        "customer_name": "Kathleen Nichols",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Brenda C.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-06-03",
        "review_rate": 4.9,
        "customer_name": "Ann Ford",
        "service_type": "Garage Door Off Track",
        "tech_name": "Jorge A.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-09-01",
        "review_rate": 5.0,
        "customer_name": "Seth Cross",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Angela S.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-07-08",
        "review_rate": 5.0,
        "customer_name": "Zachary Conley",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Kenneth G.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-10-16",
        "review_rate": 4.9,
        "customer_name": "Kathryn Shelton",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Meredith J.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-01-14",
        "review_rate": 4.9,
        "customer_name": "Jeffrey Matthews",
        "service_type": "Garage Door Repair",
        "tech_name": "Jennifer S.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-09-08",
        "review_rate": 4.8,
        "customer_name": "John Gould",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Robert W.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-02-09",
        "review_rate": 4.8,
        "customer_name": "Amy Gonzalez",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Justin E.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-03-19",
        "review_rate": 4.9,
        "customer_name": "Daniel Wang",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Cheryl K.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-12-12",
        "review_rate": 5.0,
        "customer_name": "Jimmy Howell",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "John C.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-08-27",
        "review_rate": 5.0,
        "customer_name": "Arthur Oliver",
        "service_type": "Garage Door Installation",
        "tech_name": "Karen R.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-05-28",
        "review_rate": 4.8,
        "customer_name": "Shawn Smith",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Caroline S.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-12-18",
        "review_rate": 4.9,
        "customer_name": "Elizabeth Yoder",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Jasmine C.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-09-29",
        "review_rate": 5.0,
        "customer_name": "David Jenkins",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Lisa P.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-04-23",
        "review_rate": 4.8,
        "customer_name": "Cynthia Taylor",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Rachael S.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-04-01",
        "review_rate": 5.0,
        "customer_name": "Brenda Adams",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Richard R.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-02-20",
        "review_rate": 4.8,
        "customer_name": "James Shaw",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Evan M.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-05-05",
        "review_rate": 4.9,
        "customer_name": "Michael Jacobson",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Lori R.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-09-01",
        "review_rate": 5.0,
        "customer_name": "Robert Graham",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Christopher H.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-07-19",
        "review_rate": 4.9,
        "customer_name": "Stephanie Sweeney",
        "service_type": "Garage Door Off Track",
        "tech_name": "Rodney B.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-09-30",
        "review_rate": 4.9,
        "customer_name": "Amy Chambers",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Gina D.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-11-23",
        "review_rate": 4.9,
        "customer_name": "Justin Hoover",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "April F.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-12-03",
        "review_rate": 5.0,
        "customer_name": "Dana Gutierrez",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "David R.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-12-17",
        "review_rate": 4.9,
        "customer_name": "Andrea Palmer",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Kimberly L.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-01-10",
        "review_rate": 4.9,
        "customer_name": "Taylor Rodriguez",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Jonathan F.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-02-19",
        "review_rate": 5.0,
        "customer_name": "Todd Smith",
        "service_type": "Garage Door Repair",
        "tech_name": "Jeffrey G.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-06-15",
        "review_rate": 4.9,
        "customer_name": "David Herrera",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Anthony F.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-05-02",
        "review_rate": 4.9,
        "customer_name": "Joshua Vance",
        "service_type": "Garage Door Opener",
        "tech_name": "Douglas M.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-09-15",
        "review_rate": 4.9,
        "customer_name": "Laura Paul",
        "service_type": "Garage Door Off Track",
        "tech_name": "Kathleen D.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-02-20",
        "review_rate": 4.8,
        "customer_name": "Juan Long",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Rebecca J.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-06-09",
        "review_rate": 4.9,
        "customer_name": "Kimberly Wallace",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Stacey C.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-03-16",
        "review_rate": 5.0,
        "customer_name": "Kevin Martin",
        "service_type": "Garage Door Track Repair",
        "tech_name": "James S.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-01-09",
        "review_rate": 4.8,
        "customer_name": "Natasha Hawkins",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Linda M.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-11-03",
        "review_rate": 4.9,
        "customer_name": "Eric Santos",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Susan B.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-08-05",
        "review_rate": 5.0,
        "customer_name": "Erin Smith",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Brittany W.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-03-03",
        "review_rate": 4.8,
        "customer_name": "Ashlee Stephenson",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Stephen M.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-04-09",
        "review_rate": 5.0,
        "customer_name": "Deanna Martin",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Holly A.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-01-14",
        "review_rate": 4.8,
        "customer_name": "Brenda Nichols",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Zachary M.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-06-17",
        "review_rate": 4.8,
        "customer_name": "Debra Wood",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Ryan H.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-09-24",
        "review_rate": 5.0,
        "customer_name": "John Allen",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Stephen D.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-05-26",
        "review_rate": 4.9,
        "customer_name": "Adam Zhang",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Gabriela H.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-06-30",
        "review_rate": 4.8,
        "customer_name": "Amy Russell",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Troy A.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-07-03",
        "review_rate": 4.9,
        "customer_name": "Thomas Mitchell",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Chelsea C.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-06-16",
        "review_rate": 4.8,
        "customer_name": "Michael Guerrero",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Nicole S.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-12-28",
        "review_rate": 5.0,
        "customer_name": "Carol Burgess",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Miranda R.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-09-25",
        "review_rate": 4.9,
        "customer_name": "David Nolan",
        "service_type": "Garage Door Insulation",
        "tech_name": "Alice R.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-08-31",
        "review_rate": 4.9,
        "customer_name": "Morgan Fitzgerald",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Brian W.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-08-09",
        "review_rate": 4.9,
        "customer_name": "John Burton",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Brian S.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-04-23",
        "review_rate": 4.9,
        "customer_name": "Misty Lane",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Christina N.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-01-21",
        "review_rate": 5.0,
        "customer_name": "Emily Morris",
        "service_type": "Garage Door Installation",
        "tech_name": "Donna W.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-05-04",
        "review_rate": 5.0,
        "customer_name": "Lisa Palmer",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Aimee P.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-03-29",
        "review_rate": 4.9,
        "customer_name": "Carol Contreras",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Samantha M.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-10-02",
        "review_rate": 4.8,
        "customer_name": "Daniel Scott",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Tiffany L.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-07-31",
        "review_rate": 4.8,
        "customer_name": "Andrew Richardson",
        "service_type": "Garage Door Off Track",
        "tech_name": "Kristy B.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-03-29",
        "review_rate": 4.9,
        "customer_name": "Cynthia Kelley",
        "service_type": "Garage Door Services",
        "tech_name": "John B.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-01-08",
        "review_rate": 4.9,
        "customer_name": "Mark Arnold",
        "service_type": "Garage Door Services",
        "tech_name": "Renee S.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-03-27",
        "review_rate": 4.9,
        "customer_name": "Katherine Robinson",
        "service_type": "Garage Door Off Track",
        "tech_name": "Christina M.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-03-21",
        "review_rate": 5.0,
        "customer_name": "Brandon Kelly",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Jeffery W.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-11-03",
        "review_rate": 4.9,
        "customer_name": "Tina Soto",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Erik P.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-08-24",
        "review_rate": 4.9,
        "customer_name": "Doris Reese",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Autumn P.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-05-24",
        "review_rate": 4.9,
        "customer_name": "Jennifer Weber",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Kenneth T.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-06-07",
        "review_rate": 5.0,
        "customer_name": "Dr. Teresa White",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Jose P.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-01-29",
        "review_rate": 4.9,
        "customer_name": "Daniel Riggs",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Brandon B.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-12-28",
        "review_rate": 4.9,
        "customer_name": "Matthew Smith",
        "service_type": "Garage Door Opener",
        "tech_name": "Heather S.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-01-24",
        "review_rate": 4.9,
        "customer_name": "John Villanueva",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Marcia S.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-01-07",
        "review_rate": 4.9,
        "customer_name": "Robert Watts",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "James W.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-08-13",
        "review_rate": 4.9,
        "customer_name": "Wesley Christensen",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Jose V.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-03-21",
        "review_rate": 4.8,
        "customer_name": "Sydney Shaw",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Sarah S.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-01-22",
        "review_rate": 4.9,
        "customer_name": "Paul Hayes",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Brandy L.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-12-09",
        "review_rate": 5.0,
        "customer_name": "Jennifer Edwards",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "James H.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-07-15",
        "review_rate": 5.0,
        "customer_name": "Joshua Wright",
        "service_type": "Garage Door Installation",
        "tech_name": "Jacqueline T.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-06-24",
        "review_rate": 4.9,
        "customer_name": "Patricia Jones",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Olivia T.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-05-26",
        "review_rate": 4.8,
        "customer_name": "Beth Ross",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Dale B.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-12-08",
        "review_rate": 4.9,
        "customer_name": "Elizabeth Ware",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Kevin W.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-05-28",
        "review_rate": 4.8,
        "customer_name": "Crystal Osborne",
        "service_type": "Residential Garage Door Services",
        "tech_name": "James H.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-03-13",
        "review_rate": 5.0,
        "customer_name": "Donald Jackson",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Robert P.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-07-23",
        "review_rate": 4.9,
        "customer_name": "Andrew Blair",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Susan K.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-03-10",
        "review_rate": 5.0,
        "customer_name": "Jennifer Williamson",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Tina T.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-04-30",
        "review_rate": 4.8,
        "customer_name": "Matthew Tyler",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Lisa M.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-09-25",
        "review_rate": 4.8,
        "customer_name": "Beth Howard DVM",
        "service_type": "Garage Door Services",
        "tech_name": "Heidi L.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-07-29",
        "review_rate": 4.9,
        "customer_name": "Kevin Buck",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Regina S.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-08-05",
        "review_rate": 4.8,
        "customer_name": "Joseph Salazar",
        "service_type": "Garage Door Opener",
        "tech_name": "Sarah G.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-03-15",
        "review_rate": 4.9,
        "customer_name": "Michael White",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Christopher F.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-04-12",
        "review_rate": 4.9,
        "customer_name": "Robert Rosales DVM",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Justin D.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-09-04",
        "review_rate": 4.9,
        "customer_name": "Patrick Miranda",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Lisa H.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-03-07",
        "review_rate": 4.8,
        "customer_name": "Mr. Joseph Marks",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Curtis O.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-03-26",
        "review_rate": 4.8,
        "customer_name": "Yolanda Collier",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Roy B.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-06-28",
        "review_rate": 4.9,
        "customer_name": "Mr. Brad Dean",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Bridget H.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-02-27",
        "review_rate": 4.8,
        "customer_name": "Gloria Frazier",
        "service_type": "Garage Door Repair",
        "tech_name": "Todd J.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-02-10",
        "review_rate": 4.8,
        "customer_name": "James Brown",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Thomas P.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-01-16",
        "review_rate": 4.9,
        "customer_name": "Robert Torres",
        "service_type": "Garage Door Repair",
        "tech_name": "Vanessa L.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-12-17",
        "review_rate": 4.8,
        "customer_name": "Daniel Jackson",
        "service_type": "Garage Door Opener",
        "tech_name": "Jonathan S.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-10-11",
        "review_rate": 4.9,
        "customer_name": "Michelle Brown",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "James J.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-03-28",
        "review_rate": 5.0,
        "customer_name": "Brittney Brooks",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Melody E.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-02-20",
        "review_rate": 5.0,
        "customer_name": "Kelly Horn",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Linda M.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-11-10",
        "review_rate": 4.9,
        "customer_name": "Cynthia Larson",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Aaron J.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-11-23",
        "review_rate": 4.9,
        "customer_name": "Jeffrey Walker",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Daniel G.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-01-21",
        "review_rate": 4.8,
        "customer_name": "Anna Nolan",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Brittney P.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-11-11",
        "review_rate": 4.9,
        "customer_name": "Justin Campbell",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Kimberly K.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-01-24",
        "review_rate": 4.9,
        "customer_name": "Alicia Romero",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Kevin T.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-07-29",
        "review_rate": 4.8,
        "customer_name": "Stephanie Cisneros",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Bobby L.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-02-25",
        "review_rate": 5.0,
        "customer_name": "Andrew Sanford DDS",
        "service_type": "Garage Door Opener",
        "tech_name": "Brandon W.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-02-09",
        "review_rate": 4.9,
        "customer_name": "Kevin Greene",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Bobby B.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-05-01",
        "review_rate": 5.0,
        "customer_name": "Stephanie Levine",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Billy L.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-08-04",
        "review_rate": 4.8,
        "customer_name": "Jennifer Garcia",
        "service_type": "Garage Door Installation",
        "tech_name": "Sara W.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-03-13",
        "review_rate": 4.9,
        "customer_name": "Luke Erickson",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Paige H.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-05-20",
        "review_rate": 5.0,
        "customer_name": "Susan Payne",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Heather S.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-12-13",
        "review_rate": 4.9,
        "customer_name": "Joseph Weaver",
        "service_type": "Garage Door Services",
        "tech_name": "Laurie R.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-03-17",
        "review_rate": 4.9,
        "customer_name": "Faith Smith",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Felicia R.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-09-21",
        "review_rate": 4.9,
        "customer_name": "Bradley Edwards",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Jennifer S.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-03-01",
        "review_rate": 4.8,
        "customer_name": "Andrew Bell",
        "service_type": "Garage Door Installation",
        "tech_name": "Lindsay B.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-05-27",
        "review_rate": 4.9,
        "customer_name": "Rebecca Harrison",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Evelyn S.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-03-11",
        "review_rate": 4.8,
        "customer_name": "Gabriel Jackson",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Anne L.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-09-13",
        "review_rate": 4.9,
        "customer_name": "Stephen Phelps",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Joseph W.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-01-20",
        "review_rate": 5.0,
        "customer_name": "Albert Spencer",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Christopher C.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-06-26",
        "review_rate": 4.9,
        "customer_name": "Elizabeth Johnson",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Austin W.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-10-24",
        "review_rate": 4.9,
        "customer_name": "Ashley Walker",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Kenneth G.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-12-07",
        "review_rate": 4.9,
        "customer_name": "Christopher Stevens",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Mike S.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-12-16",
        "review_rate": 4.9,
        "customer_name": "Barbara Mendoza",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Timothy H.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-08-24",
        "review_rate": 4.8,
        "customer_name": "Harold Mitchell",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Rachael P.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-05-09",
        "review_rate": 4.9,
        "customer_name": "Garrett Castillo",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Tracy T.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-11-02",
        "review_rate": 4.8,
        "customer_name": "Carol Gay",
        "service_type": "Garage Door Off Track",
        "tech_name": "Candice T.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-07-20",
        "review_rate": 4.9,
        "customer_name": "Thomas Johnson",
        "service_type": "Garage Door Off Track",
        "tech_name": "Shannon M.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-05-13",
        "review_rate": 4.9,
        "customer_name": "Stephen Hart",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Nancy N.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-05-29",
        "review_rate": 4.8,
        "customer_name": "Andrew Nichols",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Jeffrey M.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-09-24",
        "review_rate": 5.0,
        "customer_name": "Anthony Cruz",
        "service_type": "Garage Door Services",
        "tech_name": "Brian Y.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-02-23",
        "review_rate": 4.8,
        "customer_name": "Michael Smith",
        "service_type": "Garage Door Off Track",
        "tech_name": "Spencer R.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-08-03",
        "review_rate": 4.9,
        "customer_name": "Michael Jones",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Michael D.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-03-11",
        "review_rate": 4.9,
        "customer_name": "Henry Hernandez",
        "service_type": "Garage Door Insulation",
        "tech_name": "Jason A.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-07-29",
        "review_rate": 4.8,
        "customer_name": "Andrew Jordan",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Carrie H.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-09-02",
        "review_rate": 4.9,
        "customer_name": "Joseph Hardy",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Kimberly C.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-05-04",
        "review_rate": 4.9,
        "customer_name": "Joseph Johnson",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Jeffery P.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-04-14",
        "review_rate": 4.9,
        "customer_name": "Cynthia Lewis",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Christopher M.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-08-26",
        "review_rate": 4.8,
        "customer_name": "Alan Savage",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Brandy M.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-12-02",
        "review_rate": 4.9,
        "customer_name": "Tammy Lamb",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Julie M.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-10-21",
        "review_rate": 4.8,
        "customer_name": "Lisa Powers",
        "service_type": "Garage Door Installation",
        "tech_name": "Michelle J.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-10-05",
        "review_rate": 5.0,
        "customer_name": "Debra Bryant",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Allison S.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-08-06",
        "review_rate": 5.0,
        "customer_name": "John Campbell",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Stacey L.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-08-18",
        "review_rate": 4.9,
        "customer_name": "Christina Cunningham",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Amber P.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-10-23",
        "review_rate": 4.9,
        "customer_name": "Suzanne Watkins",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Kaylee G.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-08-17",
        "review_rate": 4.9,
        "customer_name": "Casey Johnson",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Patricia L.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-07-15",
        "review_rate": 5.0,
        "customer_name": "Jason Anderson",
        "service_type": "Garage Door Services",
        "tech_name": "Andrew C.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-09-15",
        "review_rate": 4.9,
        "customer_name": "Alexis Price",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Christopher S.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-03-30",
        "review_rate": 5.0,
        "customer_name": "Jesus Montgomery",
        "service_type": "Custom Garage Door Design",
        "tech_name": "William M.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-02-20",
        "review_rate": 4.8,
        "customer_name": "David Morales II",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Nicholas C.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-05-27",
        "review_rate": 4.9,
        "customer_name": "Lacey Morse",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Mark A.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-01-14",
        "review_rate": 4.9,
        "customer_name": "Glenn Richardson",
        "service_type": "Garage Door Opener",
        "tech_name": "Brianna E.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-02-24",
        "review_rate": 4.9,
        "customer_name": "Pamela Ramirez",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Michael B.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-09-20",
        "review_rate": 4.9,
        "customer_name": "Melissa Cruz DVM",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Kevin E.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-06-14",
        "review_rate": 4.9,
        "customer_name": "David Anderson",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Ashley B.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-11-15",
        "review_rate": 5.0,
        "customer_name": "Todd Wilson",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Alan H.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-03-13",
        "review_rate": 4.9,
        "customer_name": "Sarah Chan",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "David B.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-07-23",
        "review_rate": 4.9,
        "customer_name": "Tracy Sandoval",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Ronald C.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-10-06",
        "review_rate": 4.9,
        "customer_name": "Shannon May",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Jason H.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-06-15",
        "review_rate": 4.8,
        "customer_name": "Connor Johnson",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Brittany H.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-05-24",
        "review_rate": 4.9,
        "customer_name": "Melissa Thomas",
        "service_type": "Garage Door Insulation",
        "tech_name": "Janice L.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-02-10",
        "review_rate": 4.8,
        "customer_name": "Alyssa Lee",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Dennis R.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-03-12",
        "review_rate": 4.8,
        "customer_name": "Casey Hoffman",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Lori S.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-05-31",
        "review_rate": 4.8,
        "customer_name": "Robert Campbell",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Alex C.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-11-21",
        "review_rate": 5.0,
        "customer_name": "Cynthia Lynch MD",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Lori R.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-01-07",
        "review_rate": 5.0,
        "customer_name": "Joshua Rowe",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Brittany M.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-10-30",
        "review_rate": 4.9,
        "customer_name": "Thomas Hawkins",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Douglas J.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-06-02",
        "review_rate": 4.8,
        "customer_name": "Leroy Lara",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "William D.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-06-27",
        "review_rate": 4.9,
        "customer_name": "Maria Walker",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Diana H.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-06-25",
        "review_rate": 4.9,
        "customer_name": "Mark Cain",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Alex W.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-08-20",
        "review_rate": 4.9,
        "customer_name": "Joseph Barber",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Amanda K.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-12-18",
        "review_rate": 4.9,
        "customer_name": "Dominique West",
        "service_type": "Garage Door Insulation",
        "tech_name": "Allen M.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-10-28",
        "review_rate": 5.0,
        "customer_name": "Vanessa Barnes",
        "service_type": "Garage Door Services",
        "tech_name": "Michael M.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-03-03",
        "review_rate": 4.9,
        "customer_name": "Jennifer Shannon",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Rachel W.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-05-17",
        "review_rate": 4.8,
        "customer_name": "Richard George",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Kathryn R.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-12-14",
        "review_rate": 4.9,
        "customer_name": "Matthew Gonzalez",
        "service_type": "Garage Door Insulation",
        "tech_name": "Gabriel P.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-04-23",
        "review_rate": 4.8,
        "customer_name": "Justin Johnson",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Bethany S.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-04-16",
        "review_rate": 4.9,
        "customer_name": "Cindy Miller",
        "service_type": "Garage Door Insulation",
        "tech_name": "Austin M.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-07-23",
        "review_rate": 4.9,
        "customer_name": "Tina Jenkins",
        "service_type": "Garage Door Insulation",
        "tech_name": "Gabriel P.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-01-15",
        "review_rate": 4.9,
        "customer_name": "Christopher Wagner",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Ashley C.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-11-03",
        "review_rate": 4.9,
        "customer_name": "Charles Hess",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Sarah C.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-07-02",
        "review_rate": 4.8,
        "customer_name": "Mary Ward",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Benjamin F.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-12-18",
        "review_rate": 5.0,
        "customer_name": "Elizabeth Waters",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Donna O.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-04-13",
        "review_rate": 4.8,
        "customer_name": "Darrell Howard",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Kaitlin P.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-01-14",
        "review_rate": 4.8,
        "customer_name": "Gregory Finley",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Michael S.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-07-09",
        "review_rate": 4.9,
        "customer_name": "Howard Bailey",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Travis J.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-07-02",
        "review_rate": 4.9,
        "customer_name": "Traci Anderson",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Jon B.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-09-29",
        "review_rate": 4.8,
        "customer_name": "Teresa Campbell",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Valerie M.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-01-04",
        "review_rate": 4.8,
        "customer_name": "Michael Ho",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Crystal P.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-11-06",
        "review_rate": 4.8,
        "customer_name": "Jacob Rowe",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Jeffrey L.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-05-26",
        "review_rate": 5.0,
        "customer_name": "Monica Colon",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Angela R.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-05-23",
        "review_rate": 5.0,
        "customer_name": "Nicholas Cowan",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Laurie L.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-04-17",
        "review_rate": 4.9,
        "customer_name": "Jason Salas",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Marvin K.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-02-22",
        "review_rate": 4.9,
        "customer_name": "Levi Mays",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Christina D.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-07-02",
        "review_rate": 4.8,
        "customer_name": "Austin Mccullough",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "John D.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-11-08",
        "review_rate": 4.8,
        "customer_name": "Jordan Tucker",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Kimberly B.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-06-01",
        "review_rate": 4.8,
        "customer_name": "Amber Hawkins",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Emily S.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-01-28",
        "review_rate": 4.9,
        "customer_name": "Robert Banks",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Michael R.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-02-18",
        "review_rate": 4.9,
        "customer_name": "Sarah Peterson",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Angela M.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-09-02",
        "review_rate": 4.8,
        "customer_name": "Patricia Miller",
        "service_type": "Garage Door Off Track",
        "tech_name": "Ryan H.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-01-07",
        "review_rate": 4.9,
        "customer_name": "David Butler",
        "service_type": "Garage Door Services",
        "tech_name": "Emily D.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-07-21",
        "review_rate": 4.8,
        "customer_name": "Kyle Yates",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Johnathan C.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-09-15",
        "review_rate": 4.8,
        "customer_name": "Samuel Curtis",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Eddie S.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-12-31",
        "review_rate": 4.8,
        "customer_name": "Mr. Mark Hester",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Antonio H.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-05-03",
        "review_rate": 4.9,
        "customer_name": "Summer Patel",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Christopher W.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-04-22",
        "review_rate": 4.9,
        "customer_name": "Franklin Petersen",
        "service_type": "Garage Door Installation",
        "tech_name": "Jeffery O.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-03-06",
        "review_rate": 4.8,
        "customer_name": "Cheryl Edwards",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Sarah R.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-07-03",
        "review_rate": 5.0,
        "customer_name": "Jessica Cabrera",
        "service_type": "Garage Door Insulation",
        "tech_name": "Diana H.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-03-27",
        "review_rate": 4.9,
        "customer_name": "Kelly Smith",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Amanda Z.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-01-16",
        "review_rate": 5.0,
        "customer_name": "Katherine Barajas",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Stephen G.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-10-26",
        "review_rate": 5.0,
        "customer_name": "Karen Watson",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Jose R.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-03-20",
        "review_rate": 4.9,
        "customer_name": "Michael Ellis",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Karen H.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-11-25",
        "review_rate": 4.9,
        "customer_name": "Christopher Nicholson",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Michael G.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-08-10",
        "review_rate": 4.8,
        "customer_name": "Michael Bishop",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Dawn G.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-12-28",
        "review_rate": 4.9,
        "customer_name": "Megan Williams",
        "service_type": "Garage Door Opener",
        "tech_name": "Ryan P.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-08-04",
        "review_rate": 4.8,
        "customer_name": "Dale Graham",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Andrew K.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-09-02",
        "review_rate": 4.8,
        "customer_name": "John Chen",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Michelle G.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-02-11",
        "review_rate": 5.0,
        "customer_name": "Caroline Tran",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Todd L.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-03-17",
        "review_rate": 4.9,
        "customer_name": "Michael Nicholson",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Heather S.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-07-31",
        "review_rate": 5.0,
        "customer_name": "Rhonda Hamilton",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Tiffany V.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-05-18",
        "review_rate": 5.0,
        "customer_name": "John Crawford",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Betty S.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-10-30",
        "review_rate": 5.0,
        "customer_name": "Brent Stevens",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Catherine J.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-12-19",
        "review_rate": 4.9,
        "customer_name": "Colleen Harper",
        "service_type": "Garage Door Opener",
        "tech_name": "Angela R.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-04-19",
        "review_rate": 5.0,
        "customer_name": "Amanda Walker",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Patrick J.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-10-24",
        "review_rate": 4.9,
        "customer_name": "Lauren Carter",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Michael O.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-08-05",
        "review_rate": 4.9,
        "customer_name": "Kyle Owens",
        "service_type": "Garage Door Installation",
        "tech_name": "Tara D.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-05-16",
        "review_rate": 5.0,
        "customer_name": "Zachary Camacho",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Abigail A.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-07-25",
        "review_rate": 4.9,
        "customer_name": "Elizabeth Murray",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Jennifer S.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-04-24",
        "review_rate": 4.8,
        "customer_name": "Ronald Woodward",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Mark H.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-01-08",
        "review_rate": 5.0,
        "customer_name": "Eric Lucas",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Joshua S.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-03-08",
        "review_rate": 4.9,
        "customer_name": "Erica Ruiz",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Amanda S.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-07-25",
        "review_rate": 4.9,
        "customer_name": "Hannah Elliott",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Michael R.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-01-28",
        "review_rate": 4.9,
        "customer_name": "Dave Sullivan",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Todd B.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-01-09",
        "review_rate": 4.9,
        "customer_name": "Jill Brown",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Daniel M.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-09-13",
        "review_rate": 4.9,
        "customer_name": "Bryan Herrera",
        "service_type": "Garage Door Opener",
        "tech_name": "Alexander M.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-01-27",
        "review_rate": 4.8,
        "customer_name": "Evan Perez",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Mary P.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-02-13",
        "review_rate": 4.9,
        "customer_name": "Richard Simpson DDS",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Jonathan B.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-10-12",
        "review_rate": 4.9,
        "customer_name": "Diana Martin",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Jill B.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-03-18",
        "review_rate": 4.8,
        "customer_name": "Matthew Lee",
        "service_type": "Garage Door Repair",
        "tech_name": "Melissa B.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-06-30",
        "review_rate": 5.0,
        "customer_name": "Tiffany Martinez",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Heather D.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-08-20",
        "review_rate": 4.9,
        "customer_name": "Dakota Rios",
        "service_type": "Garage Door Repair",
        "tech_name": "Manuel S.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-06-11",
        "review_rate": 4.9,
        "customer_name": "Stacy Adkins",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Brian S.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-04-30",
        "review_rate": 5.0,
        "customer_name": "Jackie Brown",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Adam J.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-09-14",
        "review_rate": 5.0,
        "customer_name": "Leslie Schmidt",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Jasmine P.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-05-01",
        "review_rate": 4.8,
        "customer_name": "Amy Ellis",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Connie W.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-05-22",
        "review_rate": 4.9,
        "customer_name": "Alicia Johnson",
        "service_type": "Garage Door Installation",
        "tech_name": "Julie L.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-08-09",
        "review_rate": 4.9,
        "customer_name": "James Mccarthy",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "John K.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-02-10",
        "review_rate": 4.9,
        "customer_name": "Mrs. Cynthia Johnson",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "James D.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-01-31",
        "review_rate": 4.8,
        "customer_name": "Jeffery Bell",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Michael H.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-07-10",
        "review_rate": 5.0,
        "customer_name": "James Carlson",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Nicole R.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-04-20",
        "review_rate": 4.8,
        "customer_name": "Rodney Gaines",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Ryan M.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-11-06",
        "review_rate": 5.0,
        "customer_name": "Shane Jones",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Sandra S.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-01-28",
        "review_rate": 5.0,
        "customer_name": "Sean Williamson",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Cody W.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-02-16",
        "review_rate": 4.9,
        "customer_name": "Gary Jordan",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Kevin F.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-09-14",
        "review_rate": 5.0,
        "customer_name": "Amanda Francis",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Lauren C.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-09-03",
        "review_rate": 5.0,
        "customer_name": "Lisa Guerrero",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Eric L.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-06-23",
        "review_rate": 4.8,
        "customer_name": "Jason Gonzalez",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Michael D.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-07-11",
        "review_rate": 5.0,
        "customer_name": "Kevin Rivera",
        "service_type": "Garage Door Services",
        "tech_name": "Deborah C.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-06-21",
        "review_rate": 5.0,
        "customer_name": "Shannon Vasquez",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Katelyn A.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-09-27",
        "review_rate": 4.9,
        "customer_name": "Steven Davis",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Dawn C.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-01-25",
        "review_rate": 4.9,
        "customer_name": "Kenneth Pierce",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Austin S.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-02-25",
        "review_rate": 4.9,
        "customer_name": "Paul Gray",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Matthew M.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-08-30",
        "review_rate": 4.9,
        "customer_name": "Jennifer Williams",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "James J.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-08-04",
        "review_rate": 4.9,
        "customer_name": "Jamie Marshall",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Kathleen B.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-09-08",
        "review_rate": 4.8,
        "customer_name": "Emily Owen",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Troy W.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-02-14",
        "review_rate": 4.9,
        "customer_name": "Jeffery Avila",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Matthew R.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-04-24",
        "review_rate": 4.8,
        "customer_name": "Jesse Banks",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Richard H.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-05-18",
        "review_rate": 4.9,
        "customer_name": "Angel Bradley",
        "service_type": "Garage Door Off Track",
        "tech_name": "Hayley M.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-07-26",
        "review_rate": 4.8,
        "customer_name": "Anita Perez",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Jennifer S.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-04-27",
        "review_rate": 5.0,
        "customer_name": "Edward Kim",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Leslie M.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-06-27",
        "review_rate": 4.9,
        "customer_name": "Kristy Mason",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Philip H.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-08-02",
        "review_rate": 5.0,
        "customer_name": "Karen Rosario",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Stephanie L.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-07-03",
        "review_rate": 4.8,
        "customer_name": "Karen Smith MD",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Harold B.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-09-11",
        "review_rate": 4.9,
        "customer_name": "Dr. Natalie Davis",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "James S.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-12-30",
        "review_rate": 4.9,
        "customer_name": "Steven Booker DDS",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Gerald H.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-05-23",
        "review_rate": 4.8,
        "customer_name": "Rachel Knight",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "John G.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-04-08",
        "review_rate": 4.9,
        "customer_name": "Ashley Scott",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Larry O.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-04-21",
        "review_rate": 4.9,
        "customer_name": "Jessica Rodriguez",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Derek K.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-03-25",
        "review_rate": 4.8,
        "customer_name": "Gregory Booker",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Calvin F.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-12-07",
        "review_rate": 4.9,
        "customer_name": "Anthony Rasmussen",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Jessica G.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-12-01",
        "review_rate": 4.9,
        "customer_name": "Jane Lee",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Audrey G.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-04-28",
        "review_rate": 4.8,
        "customer_name": "Kyle Norris",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Jessica J.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-08-31",
        "review_rate": 4.9,
        "customer_name": "Danielle Marsh",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Ricky K.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-04-24",
        "review_rate": 5.0,
        "customer_name": "Steven Miller",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "John G.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-09-07",
        "review_rate": 5.0,
        "customer_name": "Miss Holly Arellano",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Angela R.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-01-19",
        "review_rate": 4.8,
        "customer_name": "Michael Foley",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Janet T.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-04-10",
        "review_rate": 4.8,
        "customer_name": "Rebecca Carter",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Brian C.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-03-05",
        "review_rate": 4.8,
        "customer_name": "Travis Johnson",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Jennifer M.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-11-23",
        "review_rate": 4.9,
        "customer_name": "Patrick Blair",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Howard S.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-02-13",
        "review_rate": 4.9,
        "customer_name": "Miss Jessica Lynch",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Brian R.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-07-11",
        "review_rate": 4.8,
        "customer_name": "Richard Green",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Sandra V.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-09-08",
        "review_rate": 4.9,
        "customer_name": "Kyle Le",
        "service_type": "Garage Door Services",
        "tech_name": "Russell S.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-03-22",
        "review_rate": 5.0,
        "customer_name": "Brandy Schroeder",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Brian N.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-01-15",
        "review_rate": 4.9,
        "customer_name": "David Bailey",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Scott B.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-05-29",
        "review_rate": 4.9,
        "customer_name": "Jonathan Garcia",
        "service_type": "Garage Door Opener",
        "tech_name": "Travis L.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-09-22",
        "review_rate": 4.9,
        "customer_name": "Elizabeth Davidson",
        "service_type": "Garage Door Insulation",
        "tech_name": "Heather T.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-08-09",
        "review_rate": 4.9,
        "customer_name": "Christina Perry",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Eugene C.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-01-28",
        "review_rate": 4.8,
        "customer_name": "Chase Williams",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Christine C.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-12-15",
        "review_rate": 4.9,
        "customer_name": "Randall Ortega",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Ashley N.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-04-30",
        "review_rate": 4.8,
        "customer_name": "Tracey Best",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Charles F.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-11-28",
        "review_rate": 5.0,
        "customer_name": "Christopher Maddox",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Brian G.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-10-17",
        "review_rate": 4.9,
        "customer_name": "Jennifer Barnes",
        "service_type": "Garage Door Insulation",
        "tech_name": "William R.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-07-29",
        "review_rate": 4.9,
        "customer_name": "Diane Rice",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Benjamin A.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-08-21",
        "review_rate": 4.8,
        "customer_name": "Brandon Valencia",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Brian W.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-03-29",
        "review_rate": 4.8,
        "customer_name": "Matthew Frazier",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Patricia S.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-07-17",
        "review_rate": 4.8,
        "customer_name": "Daniel Thompson",
        "service_type": "Garage Door Opener",
        "tech_name": "Elizabeth J.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-12-04",
        "review_rate": 4.9,
        "customer_name": "Dennis Johnson",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Kathleen R.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-06-29",
        "review_rate": 5.0,
        "customer_name": "Jessica Cortez",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Julia G.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-09-01",
        "review_rate": 5.0,
        "customer_name": "Sandra Meyers",
        "service_type": "Garage Door Repair",
        "tech_name": "Cheyenne H.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-10-08",
        "review_rate": 4.9,
        "customer_name": "Jill Peck",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Richard B.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-03-12",
        "review_rate": 4.8,
        "customer_name": "Abigail Walter",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Sean M.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-08-08",
        "review_rate": 4.9,
        "customer_name": "Lisa Waters",
        "service_type": "Garage Door Insulation",
        "tech_name": "Susan C.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-03-06",
        "review_rate": 4.8,
        "customer_name": "Alyssa Byrd",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Mike C.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-09-14",
        "review_rate": 4.9,
        "customer_name": "Walter Morse",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Tom M.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-10-13",
        "review_rate": 4.8,
        "customer_name": "Anna Evans",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Brandy N.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-12-15",
        "review_rate": 4.9,
        "customer_name": "Charles Cline",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Chelsea P.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-02-12",
        "review_rate": 4.8,
        "customer_name": "Kenneth Jones",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Meredith W.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-08-13",
        "review_rate": 4.9,
        "customer_name": "Andres Woods",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Andrew W.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-03-03",
        "review_rate": 4.8,
        "customer_name": "Christina Alvarado",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Matthew F.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-08-09",
        "review_rate": 4.8,
        "customer_name": "Richard Delgado",
        "service_type": "Garage Door Opener",
        "tech_name": "Michael F.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-10-19",
        "review_rate": 5.0,
        "customer_name": "Jennifer Mitchell",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "John N.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-05-18",
        "review_rate": 4.9,
        "customer_name": "Brandon Hudson",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Scott R.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-12-06",
        "review_rate": 4.9,
        "customer_name": "William Cannon",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Sherry B.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-08-09",
        "review_rate": 4.9,
        "customer_name": "Christine Snyder",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Regina G.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-09-29",
        "review_rate": 4.9,
        "customer_name": "Denise Costa",
        "service_type": "Garage Door Insulation",
        "tech_name": "Thomas W.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-08-09",
        "review_rate": 5.0,
        "customer_name": "Jamie Richardson",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Benjamin R.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-08-23",
        "review_rate": 4.9,
        "customer_name": "James Mathews",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "James H.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-04-21",
        "review_rate": 4.8,
        "customer_name": "Johnny Stevens",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Lauren P.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-02-25",
        "review_rate": 4.9,
        "customer_name": "Renee Carroll",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Kevin S.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-12-06",
        "review_rate": 4.9,
        "customer_name": "Christopher Taylor",
        "service_type": "Garage Door Services",
        "tech_name": "Carolyn P.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-07-13",
        "review_rate": 4.8,
        "customer_name": "Chelsea Carpenter",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Meghan W.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-12-26",
        "review_rate": 4.8,
        "customer_name": "Tara Bradley",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Maria L.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-05-22",
        "review_rate": 4.9,
        "customer_name": "Sarah Sherman",
        "service_type": "Garage Door Installation",
        "tech_name": "Leonard C.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-04-19",
        "review_rate": 5.0,
        "customer_name": "Madeline Harper MD",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Kelly A.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-09-05",
        "review_rate": 4.9,
        "customer_name": "Tiffany Adams",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Eric D.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-11-01",
        "review_rate": 5.0,
        "customer_name": "Annette Wolf",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Jasmine B.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-12-24",
        "review_rate": 4.9,
        "customer_name": "William Holt",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Samuel B.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-10-10",
        "review_rate": 4.9,
        "customer_name": "Joan Flores MD",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "David J.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-12-19",
        "review_rate": 4.9,
        "customer_name": "Brandi Hickman",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Timothy J.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-11-18",
        "review_rate": 5.0,
        "customer_name": "Roger Parks",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Matthew B.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-06-28",
        "review_rate": 4.9,
        "customer_name": "Charles Barnes",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Mark I.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-08-28",
        "review_rate": 4.9,
        "customer_name": "Keith Randall",
        "service_type": "Garage Door Off Track",
        "tech_name": "Angela A.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-04-16",
        "review_rate": 5.0,
        "customer_name": "Richard Gamble",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Steven P.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-11-20",
        "review_rate": 4.9,
        "customer_name": "Natalie Thomas",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Richard M.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-06-18",
        "review_rate": 4.9,
        "customer_name": "Kyle Walker",
        "service_type": "Garage Door Opener",
        "tech_name": "Patrick S.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-01-16",
        "review_rate": 4.8,
        "customer_name": "Karina Smith",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Christopher B.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-12-16",
        "review_rate": 4.9,
        "customer_name": "Chris Jackson",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Susan P.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-02-15",
        "review_rate": 4.8,
        "customer_name": "Brandi Smith",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Angela M.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-10-26",
        "review_rate": 4.9,
        "customer_name": "Ann Lyons",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Marcus G.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-05-31",
        "review_rate": 4.8,
        "customer_name": "Elizabeth Gordon",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Jacob B.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-08-28",
        "review_rate": 4.8,
        "customer_name": "Joel Ramos",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Bryan F.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-06-29",
        "review_rate": 4.9,
        "customer_name": "Zachary Brown",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Kelly J.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-06-11",
        "review_rate": 4.9,
        "customer_name": "Alyssa Osborne",
        "service_type": "Garage Door Repair",
        "tech_name": "Sharon W.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-03-30",
        "review_rate": 4.9,
        "customer_name": "Ashley Murphy",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Jennifer N.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-01-20",
        "review_rate": 4.9,
        "customer_name": "Matthew Griffin",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Donald B.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-09-13",
        "review_rate": 5.0,
        "customer_name": "Sean Thompson DVM",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Dana D.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-11-09",
        "review_rate": 4.9,
        "customer_name": "Erica Wilson",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Toni R.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-11-26",
        "review_rate": 4.9,
        "customer_name": "Angela Rasmussen",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Melanie M.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-08-24",
        "review_rate": 4.8,
        "customer_name": "Cynthia Murphy",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Diana T.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-02-04",
        "review_rate": 4.9,
        "customer_name": "Brittany Lucero",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Jeffrey G.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-08-22",
        "review_rate": 4.9,
        "customer_name": "Richard Parker",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Mark C.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-08-09",
        "review_rate": 4.8,
        "customer_name": "Gerald Henderson Jr.",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Julie K.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-09-26",
        "review_rate": 4.8,
        "customer_name": "Chris Pena",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Erica C.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-03-17",
        "review_rate": 5.0,
        "customer_name": "William Stewart",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Sabrina W.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-05-16",
        "review_rate": 4.9,
        "customer_name": "William Strickland",
        "service_type": "Garage Door Off Track",
        "tech_name": "Robert J.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-07-11",
        "review_rate": 4.9,
        "customer_name": "Felicia Burgess",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Angie S.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-05-10",
        "review_rate": 4.8,
        "customer_name": "Kenneth Oneal",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Erik P.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-06-12",
        "review_rate": 4.8,
        "customer_name": "Ronald Fuentes",
        "service_type": "Garage Door Services",
        "tech_name": "Karen C.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-04-01",
        "review_rate": 4.9,
        "customer_name": "Elizabeth Anderson",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Amanda H.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-10-17",
        "review_rate": 4.8,
        "customer_name": "Crystal Gordon",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Tamara L.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-09-19",
        "review_rate": 4.8,
        "customer_name": "Rachel Clark",
        "service_type": "Garage Door Repair",
        "tech_name": "Katherine W.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-11-08",
        "review_rate": 4.9,
        "customer_name": "James Johnson",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Marilyn M.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-03-31",
        "review_rate": 4.9,
        "customer_name": "Denise Ray",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Diane S.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-09-01",
        "review_rate": 4.9,
        "customer_name": "Steven Lara",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Patricia M.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-12-22",
        "review_rate": 5.0,
        "customer_name": "Sean Gould",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Yolanda H.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-12-24",
        "review_rate": 4.9,
        "customer_name": "Linda Gray",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Corey N.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-12-22",
        "review_rate": 4.8,
        "customer_name": "Amy Little",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Joshua W.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-07-05",
        "review_rate": 4.9,
        "customer_name": "Jacob Strickland MD",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Emma G.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-08-12",
        "review_rate": 4.8,
        "customer_name": "Joshua Williams",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Ryan O.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-08-28",
        "review_rate": 4.9,
        "customer_name": "Michele Kelly",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Andrew B.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-05-08",
        "review_rate": 4.9,
        "customer_name": "Joshua Ferguson",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Hannah K.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-05-26",
        "review_rate": 4.9,
        "customer_name": "Michael Fernandez",
        "service_type": "Garage Door Repair",
        "tech_name": "Kelly A.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-09-24",
        "review_rate": 4.9,
        "customer_name": "Craig Lynch",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Brittany J.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-09-12",
        "review_rate": 4.9,
        "customer_name": "Danielle Norman",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Crystal S.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-03-17",
        "review_rate": 4.9,
        "customer_name": "Taylor Flowers",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Susan S.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-05-23",
        "review_rate": 4.9,
        "customer_name": "Katie Burke",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Darrell R.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-09-09",
        "review_rate": 4.9,
        "customer_name": "Stephen Miller",
        "service_type": "Garage Door Repair",
        "tech_name": "Paul H.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-10-08",
        "review_rate": 5.0,
        "customer_name": "David Hughes",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Anna S.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-09-08",
        "review_rate": 4.8,
        "customer_name": "Breanna Long",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "David L.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-08-09",
        "review_rate": 5.0,
        "customer_name": "John Jones",
        "service_type": "Garage Door Opener",
        "tech_name": "Brent W.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-10-12",
        "review_rate": 4.8,
        "customer_name": "Julie Anderson",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Emily H.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-01-06",
        "review_rate": 4.9,
        "customer_name": "Thomas Collier",
        "service_type": "Garage Door Insulation",
        "tech_name": "Joseph M.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-01-21",
        "review_rate": 4.9,
        "customer_name": "Brian Dominguez",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Cody S.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-08-21",
        "review_rate": 4.9,
        "customer_name": "Sean Cole",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Karen N.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-11-28",
        "review_rate": 4.9,
        "customer_name": "Ronnie Moore",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Amy M.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-07-02",
        "review_rate": 4.8,
        "customer_name": "Jerry Mendoza",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Michael H.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-02-04",
        "review_rate": 4.8,
        "customer_name": "Vincent Rice",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Alan J.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-04-28",
        "review_rate": 4.9,
        "customer_name": "Janice Harrington",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Nicholas C.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-03-12",
        "review_rate": 4.9,
        "customer_name": "Samantha Grant",
        "service_type": "Garage Door Repair",
        "tech_name": "Renee G.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-09-22",
        "review_rate": 4.9,
        "customer_name": "Kathleen Goodwin",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Colton T.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-10-07",
        "review_rate": 5.0,
        "customer_name": "Todd Long",
        "service_type": "Garage Door Services",
        "tech_name": "Shannon E.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-07-19",
        "review_rate": 5.0,
        "customer_name": "Bernard Jones",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Ashley L.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-03-06",
        "review_rate": 4.9,
        "customer_name": "Carlos Cox",
        "service_type": "Garage Door Insulation",
        "tech_name": "Carla P.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-08-23",
        "review_rate": 4.9,
        "customer_name": "Aaron Hall",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Paul D.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-12-14",
        "review_rate": 5.0,
        "customer_name": "Adam Moore",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Barbara K.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-11-20",
        "review_rate": 4.8,
        "customer_name": "Stephen Owens",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "David C.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-06-24",
        "review_rate": 4.8,
        "customer_name": "John Hill",
        "service_type": "Garage Door Insulation",
        "tech_name": "Anna A.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-07-07",
        "review_rate": 4.9,
        "customer_name": "David Roach",
        "service_type": "Garage Door Opener",
        "tech_name": "Steven E.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-12-11",
        "review_rate": 4.8,
        "customer_name": "Jessica Keller",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Loretta R.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-10-30",
        "review_rate": 5.0,
        "customer_name": "Nicholas Adams",
        "service_type": "Garage Door Repair",
        "tech_name": "Matthew M.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-11-14",
        "review_rate": 4.8,
        "customer_name": "Cynthia Martinez",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Daniel B.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-10-16",
        "review_rate": 4.9,
        "customer_name": "Paul Adkins",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Stephen H.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-10-28",
        "review_rate": 4.8,
        "customer_name": "Stephen Peck",
        "service_type": "Garage Door Repair",
        "tech_name": "Andrew L.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-10-08",
        "review_rate": 4.9,
        "customer_name": "Melissa Graham",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Joseph L.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-11-24",
        "review_rate": 4.9,
        "customer_name": "Erica Lucas",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Pamela H.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-07-29",
        "review_rate": 4.8,
        "customer_name": "Leslie Kelly",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Joseph E.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-07-11",
        "review_rate": 4.9,
        "customer_name": "Jeremy Lewis",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Alicia B.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-07-08",
        "review_rate": 4.9,
        "customer_name": "Kathy Reeves",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Stephanie T.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-07-05",
        "review_rate": 4.8,
        "customer_name": "Jason Miller",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Michael T.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-01-11",
        "review_rate": 5.0,
        "customer_name": "Jessica Henderson",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Daniel C.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-08-20",
        "review_rate": 4.8,
        "customer_name": "Kelly Jackson",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Brian B.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-08-25",
        "review_rate": 4.9,
        "customer_name": "Maria Arnold",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Jennifer H.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-12-17",
        "review_rate": 5.0,
        "customer_name": "Cynthia Jones",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Joshua R.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-08-09",
        "review_rate": 4.9,
        "customer_name": "Scott Monroe",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Nicholas M.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-06-17",
        "review_rate": 5.0,
        "customer_name": "Amy Beasley",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Kathleen G.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-05-25",
        "review_rate": 4.9,
        "customer_name": "James Schmidt",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Patty K.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-04-27",
        "review_rate": 5.0,
        "customer_name": "Amy Patterson",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Jenna F.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-06-12",
        "review_rate": 4.8,
        "customer_name": "David Jennings",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Michelle M.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-10-16",
        "review_rate": 5.0,
        "customer_name": "Christina Wilson",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Connie J.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-09-10",
        "review_rate": 5.0,
        "customer_name": "Barry Mcgee",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Craig R.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-12-31",
        "review_rate": 4.9,
        "customer_name": "Kristi Salazar",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Colleen W.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-09-06",
        "review_rate": 4.9,
        "customer_name": "Ricky Smith",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Amy T.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-08-27",
        "review_rate": 5.0,
        "customer_name": "Alison White",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Sarah D.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-02-23",
        "review_rate": 4.9,
        "customer_name": "Brandon Stuart",
        "service_type": "Garage Door Off Track",
        "tech_name": "Rebecca G.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-07-31",
        "review_rate": 4.9,
        "customer_name": "Jennifer Mckinney",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Thomas H.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-04-08",
        "review_rate": 4.9,
        "customer_name": "Kathleen Warren",
        "service_type": "Garage Door Services",
        "tech_name": "Melanie M.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-09-14",
        "review_rate": 4.8,
        "customer_name": "David Clark",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Sean W.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-01-01",
        "review_rate": 4.9,
        "customer_name": "Michele Martin",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Andrea L.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-09-04",
        "review_rate": 4.8,
        "customer_name": "Terrence David",
        "service_type": "Garage Door Opener",
        "tech_name": "Barbara E.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-10-04",
        "review_rate": 5.0,
        "customer_name": "Luis Mays",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Dean R.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-12-05",
        "review_rate": 4.9,
        "customer_name": "James Fitzgerald",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Damon V.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-06-20",
        "review_rate": 4.8,
        "customer_name": "James Sutton",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Robert P.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-08-14",
        "review_rate": 4.9,
        "customer_name": "Lindsey Collins",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Robert H.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-09-14",
        "review_rate": 4.8,
        "customer_name": "Sandra Pena",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Thomas W.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-12-14",
        "review_rate": 4.8,
        "customer_name": "Michael Harris",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Kelli L.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-03-15",
        "review_rate": 4.9,
        "customer_name": "Wanda Hughes",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Brian H.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-08-19",
        "review_rate": 5.0,
        "customer_name": "Robert Salas",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Katherine T.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-01-18",
        "review_rate": 5.0,
        "customer_name": "Michael Davis",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Gina C.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-09-28",
        "review_rate": 5.0,
        "customer_name": "Grace Martinez",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "David S.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-03-14",
        "review_rate": 4.9,
        "customer_name": "Elizabeth Montes",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Devin C.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-12-19",
        "review_rate": 4.9,
        "customer_name": "Jonathan Bates",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Walter H.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-10-01",
        "review_rate": 4.9,
        "customer_name": "Anthony Morales DDS",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Jessica W.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-03-29",
        "review_rate": 4.9,
        "customer_name": "Stacey Armstrong",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Robert G.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-06-27",
        "review_rate": 5.0,
        "customer_name": "Dennis Stevens",
        "service_type": "Garage Door Services",
        "tech_name": "Elizabeth W.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-11-23",
        "review_rate": 4.9,
        "customer_name": "April Kidd",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Eddie B.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-05-16",
        "review_rate": 4.9,
        "customer_name": "Vanessa Morris",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Diane G.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-05-13",
        "review_rate": 4.9,
        "customer_name": "Amy Collins",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Monica C.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-11-08",
        "review_rate": 4.9,
        "customer_name": "Robert Oneal",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Sherry B.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-02-29",
        "review_rate": 5.0,
        "customer_name": "Vanessa Watson",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Nicole W.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-07-16",
        "review_rate": 4.9,
        "customer_name": "Kyle Silva",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Louis J.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-01-15",
        "review_rate": 4.9,
        "customer_name": "Kristin Brown",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Adam R.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-03-06",
        "review_rate": 5.0,
        "customer_name": "Janice Green",
        "service_type": "Garage Door Insulation",
        "tech_name": "Patrick C.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-11-15",
        "review_rate": 5.0,
        "customer_name": "Maureen Ramirez",
        "service_type": "Garage Door Insulation",
        "tech_name": "John O.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-07-22",
        "review_rate": 4.9,
        "customer_name": "Linda Wilson",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Garrett W.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-05-26",
        "review_rate": 4.9,
        "customer_name": "Kenneth Valdez",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Angela G.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-10-23",
        "review_rate": 4.8,
        "customer_name": "Adam Robinson",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Gregory B.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-06-08",
        "review_rate": 4.8,
        "customer_name": "Katherine Berry",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Ashley D.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-07-15",
        "review_rate": 4.9,
        "customer_name": "Robert Hodge",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Tracy J.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-03-04",
        "review_rate": 4.9,
        "customer_name": "Tonya Edwards",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Thomas W.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-01-09",
        "review_rate": 4.9,
        "customer_name": "Anthony Arnold",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Jennifer M.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-05-28",
        "review_rate": 4.8,
        "customer_name": "Christina Mcbride",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Robert R.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-02-03",
        "review_rate": 4.9,
        "customer_name": "Scott Hall",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Laura L.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-12-27",
        "review_rate": 5.0,
        "customer_name": "David Lambert",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Jackson F.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-12-19",
        "review_rate": 4.9,
        "customer_name": "Thomas James",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Jessica W.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-08-14",
        "review_rate": 5.0,
        "customer_name": "Carrie Kennedy",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "James O.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-03-26",
        "review_rate": 5.0,
        "customer_name": "Laurie Dawson",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Joe A.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-12-24",
        "review_rate": 4.9,
        "customer_name": "Katrina Ho",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Jeremiah P.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-01-03",
        "review_rate": 4.9,
        "customer_name": "Christina Ray",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Mark R.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-02-05",
        "review_rate": 5.0,
        "customer_name": "Timothy Warren",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Patrick O.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-08-11",
        "review_rate": 4.8,
        "customer_name": "Stephanie Kelly",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Jose C.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-07-18",
        "review_rate": 4.9,
        "customer_name": "Adriana Herman",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Andrew M.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-08-14",
        "review_rate": 5.0,
        "customer_name": "Rhonda Blevins",
        "service_type": "Garage Door Insulation",
        "tech_name": "Crystal T.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-08-26",
        "review_rate": 4.8,
        "customer_name": "Donna Rush",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Gary R.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-03-27",
        "review_rate": 4.9,
        "customer_name": "Scott Lawson",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Rodney T.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-03-25",
        "review_rate": 5.0,
        "customer_name": "Robert Brennan",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Theodore M.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-09-22",
        "review_rate": 4.9,
        "customer_name": "Joshua Jones",
        "service_type": "Garage Door Repair",
        "tech_name": "Virginia A.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-11-19",
        "review_rate": 5.0,
        "customer_name": "Miguel Davis",
        "service_type": "Garage Door Repair",
        "tech_name": "Samantha B.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-06-04",
        "review_rate": 4.9,
        "customer_name": "Roy Jacobs",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Sergio W.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-02-03",
        "review_rate": 4.9,
        "customer_name": "Amanda Bates",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Jennifer S.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-10-27",
        "review_rate": 4.9,
        "customer_name": "Russell Rosario",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Joseph W.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-04-04",
        "review_rate": 4.8,
        "customer_name": "Juan Brown",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Jessica L.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-03-22",
        "review_rate": 5.0,
        "customer_name": "Gregory Johnson",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Christie B.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-03-25",
        "review_rate": 4.8,
        "customer_name": "Lawrence Evans",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Joseph P.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-06-25",
        "review_rate": 5.0,
        "customer_name": "Anthony Salazar",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Catherine S.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-06-28",
        "review_rate": 5.0,
        "customer_name": "Priscilla Wood",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Nathan L.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-06-23",
        "review_rate": 4.9,
        "customer_name": "Joshua Klein",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Brooke C.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-02-05",
        "review_rate": 4.8,
        "customer_name": "Pamela Peterson",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Gary H.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-05-10",
        "review_rate": 4.9,
        "customer_name": "Bailey Paul",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Joseph K.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-03-25",
        "review_rate": 4.9,
        "customer_name": "Ryan Lindsey",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Kelly F.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-06-15",
        "review_rate": 4.9,
        "customer_name": "Zachary West",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Margaret R.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-11-30",
        "review_rate": 4.9,
        "customer_name": "Megan Wilson",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Courtney W.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-01-04",
        "review_rate": 5.0,
        "customer_name": "Damon Whitaker",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Maria M.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-09-16",
        "review_rate": 5.0,
        "customer_name": "Ernest Osborne",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Daniel R.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-08-04",
        "review_rate": 4.9,
        "customer_name": "Paul Lucas",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Jennifer W.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-10-30",
        "review_rate": 4.9,
        "customer_name": "Rachel Nichols",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Nancy L.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-03-30",
        "review_rate": 5.0,
        "customer_name": "Juan Peters",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Laurie P.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-06-06",
        "review_rate": 5.0,
        "customer_name": "Alison Garrison",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Barbara B.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-02-05",
        "review_rate": 4.9,
        "customer_name": "Kellie Morrow",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Jason T.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-07-27",
        "review_rate": 4.9,
        "customer_name": "Courtney Castillo",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Bill H.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-10-21",
        "review_rate": 4.8,
        "customer_name": "Ralph Chavez",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Sarah R.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-01-26",
        "review_rate": 4.9,
        "customer_name": "Pamela Estes",
        "service_type": "Garage Door Installation",
        "tech_name": "Kathleen K.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-03-02",
        "review_rate": 4.9,
        "customer_name": "Kevin Luna",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Nathaniel C.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-03-20",
        "review_rate": 5.0,
        "customer_name": "Tyler Brown",
        "service_type": "Garage Door Insulation",
        "tech_name": "Erika L.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-01-18",
        "review_rate": 5.0,
        "customer_name": "Ronald Castillo",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "William M.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-12-11",
        "review_rate": 5.0,
        "customer_name": "William Bryant",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Heather B.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-01-25",
        "review_rate": 4.8,
        "customer_name": "Aimee Greer",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Bridget J.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-02-05",
        "review_rate": 4.9,
        "customer_name": "Kevin Miles",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Jennifer S.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-03-30",
        "review_rate": 4.9,
        "customer_name": "Theresa Maxwell",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Tracy H.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-07-30",
        "review_rate": 4.9,
        "customer_name": "Kim Molina",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Lynn O.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-09-25",
        "review_rate": 4.9,
        "customer_name": "Brenda Johnson",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Christine L.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-11-01",
        "review_rate": 5.0,
        "customer_name": "Briana Washington",
        "service_type": "Garage Door Off Track",
        "tech_name": "Ethan B.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-04-08",
        "review_rate": 4.9,
        "customer_name": "Terri Mcmillan",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Michelle H.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-08-19",
        "review_rate": 5.0,
        "customer_name": "Michele Ford",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "David P.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-03-11",
        "review_rate": 4.9,
        "customer_name": "Bryan Velasquez",
        "service_type": "Garage Door Insulation",
        "tech_name": "Melissa R.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-10-26",
        "review_rate": 4.8,
        "customer_name": "Juan Ayers",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Mitchell H.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-05-10",
        "review_rate": 4.9,
        "customer_name": "Michael Hampton",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Jeffrey B.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-07-22",
        "review_rate": 4.9,
        "customer_name": "Kevin Gonzalez",
        "service_type": "Garage Door Insulation",
        "tech_name": "Brittany N.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-11-25",
        "review_rate": 4.8,
        "customer_name": "Beth Vincent",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Rebecca O.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-08-23",
        "review_rate": 4.9,
        "customer_name": "Madison Wilson",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Madison M.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-03-06",
        "review_rate": 4.8,
        "customer_name": "John Brown",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Richard D.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-01-08",
        "review_rate": 5.0,
        "customer_name": "Anthony Gonzalez",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Kyle S.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-01-05",
        "review_rate": 4.8,
        "customer_name": "Amanda Walker",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Amanda W.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-04-12",
        "review_rate": 5.0,
        "customer_name": "Christopher Fischer",
        "service_type": "Garage Door Opener",
        "tech_name": "Cory S.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-12-16",
        "review_rate": 4.9,
        "customer_name": "Daniel Cook",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Michael G.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-10-23",
        "review_rate": 4.9,
        "customer_name": "Mr. Jonathan Higgins",
        "service_type": "Garage Door Opener",
        "tech_name": "Matthew M.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-11-11",
        "review_rate": 5.0,
        "customer_name": "James Bolton",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Jennifer A.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-06-08",
        "review_rate": 4.8,
        "customer_name": "Kelsey Murray",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Janice J.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-11-03",
        "review_rate": 4.9,
        "customer_name": "Tamara Campbell",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Robert C.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-06-19",
        "review_rate": 4.9,
        "customer_name": "Natasha Smith",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Jared T.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-10-29",
        "review_rate": 4.8,
        "customer_name": "Michelle Wilson",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Michele B.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-04-14",
        "review_rate": 5.0,
        "customer_name": "Cody Wilkerson",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Lisa C.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-03-21",
        "review_rate": 4.8,
        "customer_name": "William Thompson",
        "service_type": "Garage Door Opener",
        "tech_name": "Alexis V.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-07-12",
        "review_rate": 4.8,
        "customer_name": "Angela Williams",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Tyler G.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-01-08",
        "review_rate": 4.9,
        "customer_name": "Timothy Hill",
        "service_type": "Garage Door Opener",
        "tech_name": "Derek V.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-09-19",
        "review_rate": 4.9,
        "customer_name": "Andrea Gonzales",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Carol D.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-11-28",
        "review_rate": 4.9,
        "customer_name": "Katelyn Atkinson MD",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "James T.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-04-23",
        "review_rate": 4.9,
        "customer_name": "Patrick Parks",
        "service_type": "Garage Door Repair",
        "tech_name": "Francisco M.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-01-09",
        "review_rate": 4.9,
        "customer_name": "Edward Martin",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Corey P.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-05-14",
        "review_rate": 4.9,
        "customer_name": "Justin Gross",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Kimberly H.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-07-28",
        "review_rate": 4.9,
        "customer_name": "Curtis Cardenas",
        "service_type": "Garage Door Repair",
        "tech_name": "Shelly S.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-02-16",
        "review_rate": 5.0,
        "customer_name": "Tammy Mckenzie",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Philip L.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-07-19",
        "review_rate": 5.0,
        "customer_name": "Donald Fisher",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Brian A.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-08-26",
        "review_rate": 4.9,
        "customer_name": "Sharon Turner",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Matthew R.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-12-21",
        "review_rate": 4.8,
        "customer_name": "Robert Miller",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Kristin W.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-09-14",
        "review_rate": 4.8,
        "customer_name": "Jeffrey Parrish",
        "service_type": "Garage Door Repair",
        "tech_name": "Diana E.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-06-06",
        "review_rate": 4.8,
        "customer_name": "Erica Atkinson",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Alex R.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-08-07",
        "review_rate": 5.0,
        "customer_name": "Karen Allen",
        "service_type": "Garage Door Services",
        "tech_name": "Amy C.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-06-06",
        "review_rate": 4.9,
        "customer_name": "Ms. Christine Morgan MD",
        "service_type": "Garage Door Off Track",
        "tech_name": "Crystal N.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-06-11",
        "review_rate": 4.9,
        "customer_name": "Denise Campbell",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Mary W.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-06-27",
        "review_rate": 5.0,
        "customer_name": "Blake Moore",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "David L.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-07-23",
        "review_rate": 4.8,
        "customer_name": "Vincent Robertson",
        "service_type": "Garage Door Opener",
        "tech_name": "Susan R.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-08-03",
        "review_rate": 4.9,
        "customer_name": "Tiffany Cox",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Rebecca V.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-11-13",
        "review_rate": 5.0,
        "customer_name": "Shane Guerrero",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Daisy J.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-02-06",
        "review_rate": 4.9,
        "customer_name": "Anthony Mosley",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Jennifer K.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-07-07",
        "review_rate": 4.8,
        "customer_name": "Latasha Martin",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Danielle M.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-05-12",
        "review_rate": 4.9,
        "customer_name": "Tracey Schmidt",
        "service_type": "Garage Door Off Track",
        "tech_name": "Christina T.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-10-18",
        "review_rate": 4.9,
        "customer_name": "Leslie Brown",
        "service_type": "Garage Door Installation",
        "tech_name": "Julie S.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-03-26",
        "review_rate": 5.0,
        "customer_name": "Brooke Sheppard",
        "service_type": "Garage Door Services",
        "tech_name": "David H.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-11-11",
        "review_rate": 5.0,
        "customer_name": "Kevin Dyer",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Robert P.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-05-01",
        "review_rate": 4.8,
        "customer_name": "Tracy Jones",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Carlos G.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-03-11",
        "review_rate": 4.9,
        "customer_name": "Christopher Vega",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Melissa P.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-08-02",
        "review_rate": 4.9,
        "customer_name": "Heather Frye",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Tina N.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-12-02",
        "review_rate": 4.9,
        "customer_name": "Jennifer Dominguez",
        "service_type": "Garage Door Opener",
        "tech_name": "Taylor P.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-03-15",
        "review_rate": 4.9,
        "customer_name": "Eric Johnson",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Jim A.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-04-04",
        "review_rate": 4.8,
        "customer_name": "Andrew Castro",
        "service_type": "Garage Door Insulation",
        "tech_name": "Nathaniel T.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-11-12",
        "review_rate": 4.9,
        "customer_name": "Roy Duke",
        "service_type": "Garage Door Services",
        "tech_name": "Sherri L.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-12-24",
        "review_rate": 5.0,
        "customer_name": "Kelsey Conway",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Alexandra C.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-09-29",
        "review_rate": 4.9,
        "customer_name": "Christopher Whitney",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Alicia Y.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-11-13",
        "review_rate": 5.0,
        "customer_name": "Charles Bell",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "David C.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-09-11",
        "review_rate": 5.0,
        "customer_name": "Gary Evans",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Chelsey W.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-02-26",
        "review_rate": 4.9,
        "customer_name": "Renee Knight",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Robert J.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-11-28",
        "review_rate": 5.0,
        "customer_name": "Nicole Ferguson",
        "service_type": "Garage Door Services",
        "tech_name": "Lisa B.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-05-02",
        "review_rate": 4.8,
        "customer_name": "Gabriel Diaz",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Rhonda S.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-08-19",
        "review_rate": 4.9,
        "customer_name": "Erin Robinson",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Chad C.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-02-27",
        "review_rate": 5.0,
        "customer_name": "Donald White",
        "service_type": "Garage Door Off Track",
        "tech_name": "Jessica M.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-07-28",
        "review_rate": 4.9,
        "customer_name": "Keith Glover",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Christina C.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-11-04",
        "review_rate": 4.9,
        "customer_name": "Stephanie George",
        "service_type": "Garage Door Insulation",
        "tech_name": "Gerald J.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-10-02",
        "review_rate": 4.8,
        "customer_name": "Karen Barrett",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Savannah S.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-06-07",
        "review_rate": 5.0,
        "customer_name": "Zachary Holmes",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Lauren J.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-11-05",
        "review_rate": 4.9,
        "customer_name": "Linda Norris",
        "service_type": "Garage Door Insulation",
        "tech_name": "Noah R.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-11-01",
        "review_rate": 4.9,
        "customer_name": "Dylan Castaneda",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Donald C.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-11-09",
        "review_rate": 4.9,
        "customer_name": "Adriana Mcdonald",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Steven L.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-06-30",
        "review_rate": 4.9,
        "customer_name": "Tammy Matthews",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Martin G.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-08-01",
        "review_rate": 5.0,
        "customer_name": "Jeffrey Morris",
        "service_type": "Garage Door Repair",
        "tech_name": "David M.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-05-30",
        "review_rate": 4.8,
        "customer_name": "Jeffery Chavez",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Jack C.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-11-02",
        "review_rate": 4.9,
        "customer_name": "Madison Mcdowell",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Jessica K.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-11-08",
        "review_rate": 4.9,
        "customer_name": "Katherine Brandt",
        "service_type": "Garage Door Off Track",
        "tech_name": "Bruce W.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-04-21",
        "review_rate": 4.8,
        "customer_name": "Christopher Olson",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Christian B.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-04-16",
        "review_rate": 4.9,
        "customer_name": "Tyler Wall",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Jesse C.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-07-23",
        "review_rate": 5.0,
        "customer_name": "Matthew Allen",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "James P.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-09-25",
        "review_rate": 5.0,
        "customer_name": "Stephen Tucker",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Scott S.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-10-13",
        "review_rate": 4.9,
        "customer_name": "Nicole Meyer",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Amber C.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-04-26",
        "review_rate": 4.9,
        "customer_name": "Jorge Brooks",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Gregory R.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-04-01",
        "review_rate": 5.0,
        "customer_name": "Kyle Morris",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Melissa G.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-12-30",
        "review_rate": 4.8,
        "customer_name": "Travis White",
        "service_type": "Garage Door Services",
        "tech_name": "Patricia P.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-04-11",
        "review_rate": 4.9,
        "customer_name": "Richard Santana",
        "service_type": "Garage Door Insulation",
        "tech_name": "Christina O.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-03-31",
        "review_rate": 5.0,
        "customer_name": "Carla Bean",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Crystal H.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-06-26",
        "review_rate": 4.8,
        "customer_name": "Tracey Vazquez",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Lisa P.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-06-01",
        "review_rate": 4.8,
        "customer_name": "Kristen Brown",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Jessica W.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-01-28",
        "review_rate": 4.9,
        "customer_name": "Tammy Smith",
        "service_type": "Garage Door Opener",
        "tech_name": "Shannon D.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-01-04",
        "review_rate": 5.0,
        "customer_name": "Steven Casey",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Sharon N.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-03-01",
        "review_rate": 4.8,
        "customer_name": "Denise Hudson",
        "service_type": "Garage Door Installation",
        "tech_name": "Brenda G.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-02-08",
        "review_rate": 5.0,
        "customer_name": "Andrew Smith",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Tabitha R.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-07-19",
        "review_rate": 4.9,
        "customer_name": "Mandy Robinson",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Justin V.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-06-23",
        "review_rate": 5.0,
        "customer_name": "Gary Bell",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Tammy G.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-10-25",
        "review_rate": 4.8,
        "customer_name": "Tyler Smith",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Juan C.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-07-22",
        "review_rate": 5.0,
        "customer_name": "Zachary Conway",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Lindsay H.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-05-05",
        "review_rate": 4.8,
        "customer_name": "Christopher Tapia",
        "service_type": "Garage Door Services",
        "tech_name": "Jennifer A.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-05-17",
        "review_rate": 4.9,
        "customer_name": "Michael Thompson",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Juan B.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-11-26",
        "review_rate": 4.8,
        "customer_name": "Janice Glover",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Catherine F.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-06-08",
        "review_rate": 4.9,
        "customer_name": "Kayla Ochoa",
        "service_type": "Garage Door Opener",
        "tech_name": "Carrie T.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-07-08",
        "review_rate": 4.9,
        "customer_name": "Amber Chang",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Keith L.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-04-18",
        "review_rate": 4.9,
        "customer_name": "Jessica Castillo",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Kelli T.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-07-19",
        "review_rate": 5.0,
        "customer_name": "April Garza",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Maurice L.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-05-15",
        "review_rate": 4.8,
        "customer_name": "Jillian Banks",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Maria D.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-01-25",
        "review_rate": 5.0,
        "customer_name": "Jason Hood",
        "service_type": "Garage Door Installation",
        "tech_name": "Sharon J.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-08-15",
        "review_rate": 4.9,
        "customer_name": "Cynthia Rosales",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Clinton Z.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-08-03",
        "review_rate": 4.8,
        "customer_name": "Crystal Fields",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Jerry R.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-12-21",
        "review_rate": 4.9,
        "customer_name": "Jennifer Garcia",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Lauren L.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-12-25",
        "review_rate": 4.9,
        "customer_name": "Angel Taylor",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "David J.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-05-23",
        "review_rate": 4.8,
        "customer_name": "Kyle James",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Grace S.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-04-12",
        "review_rate": 4.9,
        "customer_name": "Eric Anderson",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Lucas J.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-05-24",
        "review_rate": 5.0,
        "customer_name": "Christine Summers",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Patricia G.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-05-20",
        "review_rate": 4.8,
        "customer_name": "Raymond Duncan MD",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Amy C.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-01-13",
        "review_rate": 4.8,
        "customer_name": "Jonathon Mcdonald",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Allison R.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-08-27",
        "review_rate": 4.8,
        "customer_name": "Cody Jackson",
        "service_type": "Garage Door Insulation",
        "tech_name": "Cynthia M.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-06-23",
        "review_rate": 5.0,
        "customer_name": "Amy Lawrence",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Kenneth L.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-01-30",
        "review_rate": 4.9,
        "customer_name": "Catherine Jones",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Jennifer C.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-07-03",
        "review_rate": 4.9,
        "customer_name": "Jennifer Rodgers",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Michael C.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-03-29",
        "review_rate": 4.8,
        "customer_name": "Michelle Hall",
        "service_type": "Garage Door Off Track",
        "tech_name": "Rebecca H.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-10-15",
        "review_rate": 4.8,
        "customer_name": "Michael Gregory",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Caroline R.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-09-29",
        "review_rate": 4.8,
        "customer_name": "Kenneth Henson",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Cheryl D.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-09-04",
        "review_rate": 5.0,
        "customer_name": "Jason Cunningham",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Jamie K.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-06-09",
        "review_rate": 4.9,
        "customer_name": "James Gates",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Mary W.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-11-26",
        "review_rate": 4.8,
        "customer_name": "Margaret Schneider",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Timothy J.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-08-24",
        "review_rate": 4.9,
        "customer_name": "Noah Brown",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Joshua B.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-05-19",
        "review_rate": 4.9,
        "customer_name": "Jean Knight",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Albert B.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-07-12",
        "review_rate": 5.0,
        "customer_name": "Jason Jackson",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Jorge V.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-08-16",
        "review_rate": 5.0,
        "customer_name": "William Larson PhD",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Jeffrey R.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-04-26",
        "review_rate": 4.9,
        "customer_name": "Lisa Martinez",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Jeffery C.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-06-18",
        "review_rate": 4.9,
        "customer_name": "Brian Green",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Robin H.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-10-18",
        "review_rate": 4.9,
        "customer_name": "John Boyd",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Erika R.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-07-30",
        "review_rate": 5.0,
        "customer_name": "Gabriel Anderson",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Chelsea P.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-10-06",
        "review_rate": 4.9,
        "customer_name": "Derrick Moore",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Amanda M.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-05-27",
        "review_rate": 4.9,
        "customer_name": "Brandon Craig",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Stacy V.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-12-15",
        "review_rate": 4.9,
        "customer_name": "Victoria Perez",
        "service_type": "Garage Door Opener",
        "tech_name": "Cindy F.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-01-17",
        "review_rate": 4.8,
        "customer_name": "Carrie Brown",
        "service_type": "Garage Door Services",
        "tech_name": "Justin N.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-02-25",
        "review_rate": 4.9,
        "customer_name": "Sheila Thompson",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Thomas A.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-07-22",
        "review_rate": 4.9,
        "customer_name": "Edwin Smith",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Diane J.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-05-27",
        "review_rate": 5.0,
        "customer_name": "Stacie Martin",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Erin M.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-10-08",
        "review_rate": 4.9,
        "customer_name": "Kimberly Moreno",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Andrea F.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-11-29",
        "review_rate": 4.9,
        "customer_name": "Daniel Lowery",
        "service_type": "Garage Door Opener",
        "tech_name": "Loretta C.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-11-22",
        "review_rate": 5.0,
        "customer_name": "Brandon Mueller",
        "service_type": "Garage Door Insulation",
        "tech_name": "Jason W.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-10-27",
        "review_rate": 5.0,
        "customer_name": "John Sandoval",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Nicholas M.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-10-20",
        "review_rate": 4.8,
        "customer_name": "Colleen Riley",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "John L.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-08-14",
        "review_rate": 4.8,
        "customer_name": "David Forbes",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Nicole D.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-01-20",
        "review_rate": 4.8,
        "customer_name": "Madison Parker",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Mark P.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-05-11",
        "review_rate": 4.9,
        "customer_name": "Dr. Jesse Johnston",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Kelly M.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-09-15",
        "review_rate": 5.0,
        "customer_name": "Beth Jones",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Michael W.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-06-19",
        "review_rate": 5.0,
        "customer_name": "Corey Robertson",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Margaret C.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-09-24",
        "review_rate": 4.9,
        "customer_name": "Michelle Flores",
        "service_type": "Garage Door Insulation",
        "tech_name": "Elizabeth B.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-06-14",
        "review_rate": 4.8,
        "customer_name": "Angela Kelly",
        "service_type": "Garage Door Opener",
        "tech_name": "Troy G.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-03-14",
        "review_rate": 4.8,
        "customer_name": "David Lane",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Lance K.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-03-18",
        "review_rate": 4.9,
        "customer_name": "Kimberly Duncan",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Jennifer B.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-12-30",
        "review_rate": 4.8,
        "customer_name": "Christian Ramos",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Rhonda R.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-10-10",
        "review_rate": 4.8,
        "customer_name": "David Kelly",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Michael A.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-02-08",
        "review_rate": 4.8,
        "customer_name": "Erin Wilson",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Jay P.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-10-15",
        "review_rate": 4.8,
        "customer_name": "Benjamin Day",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Jeremiah H.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-07-24",
        "review_rate": 4.8,
        "customer_name": "Lawrence Schwartz",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "John D.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-04-25",
        "review_rate": 4.8,
        "customer_name": "Richard Simmons",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Sarah G.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-04-25",
        "review_rate": 4.9,
        "customer_name": "Todd Jenkins",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Anthony H.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-03-27",
        "review_rate": 4.9,
        "customer_name": "Hannah Jones",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Crystal V.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-08-20",
        "review_rate": 4.8,
        "customer_name": "John Edwards",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Allison T.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-10-31",
        "review_rate": 5.0,
        "customer_name": "Bobby Parsons",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Debra T.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-06-09",
        "review_rate": 4.8,
        "customer_name": "Corey Bowman",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Jennifer E.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-01-16",
        "review_rate": 5.0,
        "customer_name": "Denise Rose",
        "service_type": "Garage Door Opener",
        "tech_name": "Nicole H.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-11-13",
        "review_rate": 4.8,
        "customer_name": "Brenda Fleming",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Martin F.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-04-19",
        "review_rate": 4.9,
        "customer_name": "Elizabeth Lewis",
        "service_type": "Garage Door Off Track",
        "tech_name": "Jamie M.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-06-06",
        "review_rate": 5.0,
        "customer_name": "Angela Hodge",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Sara B.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-10-17",
        "review_rate": 4.8,
        "customer_name": "William Green",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Krista S.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-06-03",
        "review_rate": 4.8,
        "customer_name": "Jeremy Smith",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Samantha S.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-12-21",
        "review_rate": 4.8,
        "customer_name": "Ricky Rowe",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Ryan P.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-10-24",
        "review_rate": 5.0,
        "customer_name": "Aaron Martinez",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Katie J.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-10-24",
        "review_rate": 4.9,
        "customer_name": "Tammy Taylor",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Carolyn B.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-08-02",
        "review_rate": 4.9,
        "customer_name": "Samantha Randolph",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Ryan P.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-09-13",
        "review_rate": 4.9,
        "customer_name": "Gabriel Johnson",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Heather B.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-01-22",
        "review_rate": 4.9,
        "customer_name": "Brian White",
        "service_type": "Garage Door Repair",
        "tech_name": "William B.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-06-25",
        "review_rate": 5.0,
        "customer_name": "Stanley Estrada",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Evelyn Y.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-02-17",
        "review_rate": 4.9,
        "customer_name": "Lisa Watts",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Julie M.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-05-16",
        "review_rate": 4.9,
        "customer_name": "Brittany Wilson",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Nicole J.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-10-11",
        "review_rate": 4.8,
        "customer_name": "Gregory Schroeder",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Ronald R.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-03-14",
        "review_rate": 5.0,
        "customer_name": "Jennifer Neal",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Spencer G.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-04-09",
        "review_rate": 5.0,
        "customer_name": "Frank Kim MD",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Alexis B.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-04-07",
        "review_rate": 5.0,
        "customer_name": "Jonathan Paul",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Tiffany S.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-08-03",
        "review_rate": 4.8,
        "customer_name": "Lucas Ramirez",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Nathaniel C.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-01-09",
        "review_rate": 4.9,
        "customer_name": "Dawn Daniels",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Anthony J.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-06-04",
        "review_rate": 4.9,
        "customer_name": "Jeremy Wheeler",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Anthony M.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-04-01",
        "review_rate": 4.8,
        "customer_name": "Carrie Chapman MD",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Anthony S.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-02-27",
        "review_rate": 4.8,
        "customer_name": "Courtney Silva",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Erica B.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-10-30",
        "review_rate": 4.9,
        "customer_name": "David Carter",
        "service_type": "Garage Door Insulation",
        "tech_name": "Andrew Y.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-03-02",
        "review_rate": 4.8,
        "customer_name": "Bryan Mitchell",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Wanda S.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-05-22",
        "review_rate": 4.8,
        "customer_name": "Sonia Meza",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "John P.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-03-15",
        "review_rate": 5.0,
        "customer_name": "Jeffrey Gomez",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Deborah C.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-08-03",
        "review_rate": 5.0,
        "customer_name": "Jessica Macdonald",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Daniel F.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-09-10",
        "review_rate": 4.8,
        "customer_name": "Alexis Santiago",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Samuel H.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-02-10",
        "review_rate": 5.0,
        "customer_name": "Abigail Jackson",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Billy B.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-01-21",
        "review_rate": 4.9,
        "customer_name": "Joseph Anderson",
        "service_type": "Garage Door Repair",
        "tech_name": "Jermaine Y.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-05-16",
        "review_rate": 4.9,
        "customer_name": "Michael Larson",
        "service_type": "Garage Door Installation",
        "tech_name": "Wanda T.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-06-17",
        "review_rate": 4.9,
        "customer_name": "Christopher Harvey",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Connor M.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-04-17",
        "review_rate": 4.9,
        "customer_name": "Dr. Troy Sexton DDS",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Jacob P.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-01-21",
        "review_rate": 4.9,
        "customer_name": "Jeffery Henry",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Rose R.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-12-04",
        "review_rate": 5.0,
        "customer_name": "Brittany Nelson",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Brian B.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-07-27",
        "review_rate": 5.0,
        "customer_name": "Carrie Rangel",
        "service_type": "Garage Door Opener",
        "tech_name": "Jon M.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-08-11",
        "review_rate": 4.9,
        "customer_name": "Lauren Knight",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Nicholas N.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-02-23",
        "review_rate": 4.9,
        "customer_name": "Sherri Vega",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Charles B.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-12-12",
        "review_rate": 4.8,
        "customer_name": "Daniel White",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Richard B.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-12-05",
        "review_rate": 4.8,
        "customer_name": "Alexis Ponce",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "William M.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-07-12",
        "review_rate": 4.9,
        "customer_name": "George Mclaughlin",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Dillon L.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-01-16",
        "review_rate": 4.8,
        "customer_name": "Robert Frey",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "David G.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-10-03",
        "review_rate": 4.9,
        "customer_name": "Holly Harvey",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Nicole S.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-10-05",
        "review_rate": 4.9,
        "customer_name": "Leslie Wilson",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Kaylee C.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-02-16",
        "review_rate": 4.9,
        "customer_name": "Amy Morgan",
        "service_type": "Garage Door Off Track",
        "tech_name": "Jessica H.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-01-17",
        "review_rate": 4.9,
        "customer_name": "Jessica Shah",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Ashley N.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-07-02",
        "review_rate": 4.9,
        "customer_name": "Jeffrey Bennett",
        "service_type": "Garage Door Insulation",
        "tech_name": "Anne S.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-10-30",
        "review_rate": 4.8,
        "customer_name": "Elizabeth Lynn",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Michael L.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-09-08",
        "review_rate": 4.9,
        "customer_name": "Amanda Harris",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "John G.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-10-26",
        "review_rate": 4.8,
        "customer_name": "Megan Collins",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Benjamin M.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-08-05",
        "review_rate": 4.9,
        "customer_name": "Jennifer Smith",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Melissa M.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-02-12",
        "review_rate": 4.8,
        "customer_name": "Cindy Davis",
        "service_type": "Garage Door Services",
        "tech_name": "Sarah M.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-12-13",
        "review_rate": 4.9,
        "customer_name": "Michael Rodriguez",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Alfred C.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-09-25",
        "review_rate": 5.0,
        "customer_name": "David Macdonald",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Caroline D.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-11-24",
        "review_rate": 4.9,
        "customer_name": "Sara Campbell",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Jeffrey R.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-12-08",
        "review_rate": 4.8,
        "customer_name": "William Randolph",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Misty B.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-11-21",
        "review_rate": 4.9,
        "customer_name": "Brandon Bryan",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Lisa B.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-06-20",
        "review_rate": 4.9,
        "customer_name": "Jamie Bell",
        "service_type": "Garage Door Insulation",
        "tech_name": "Brendan F.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-06-07",
        "review_rate": 4.9,
        "customer_name": "Edwin Peterson",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Amy M.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-05-11",
        "review_rate": 4.8,
        "customer_name": "Rita Lane",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Sydney E.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-02-29",
        "review_rate": 4.8,
        "customer_name": "Tammy Smith",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Holly M.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-07-08",
        "review_rate": 4.9,
        "customer_name": "Jeffrey Vasquez",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Dakota H.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-11-08",
        "review_rate": 5.0,
        "customer_name": "Elizabeth Reyes",
        "service_type": "Garage Door Off Track",
        "tech_name": "Sydney M.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-09-08",
        "review_rate": 4.8,
        "customer_name": "Raymond Dennis",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Joseph R.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-09-11",
        "review_rate": 4.8,
        "customer_name": "Christopher Gray",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Billy P.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-06-16",
        "review_rate": 4.8,
        "customer_name": "Nancy Morgan",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Jamie P.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-04-09",
        "review_rate": 4.9,
        "customer_name": "Jason Smith",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Andrew B.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-09-06",
        "review_rate": 4.9,
        "customer_name": "Patricia Williams",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Austin G.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-05-18",
        "review_rate": 4.9,
        "customer_name": "Martha Holmes",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "David V.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-05-14",
        "review_rate": 5.0,
        "customer_name": "Mallory Leach",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Brandon H.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-08-09",
        "review_rate": 4.8,
        "customer_name": "Joshua Stevens",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "David B.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-08-25",
        "review_rate": 4.9,
        "customer_name": "Michael Davis",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Lindsey S.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-10-17",
        "review_rate": 4.9,
        "customer_name": "Thomas Smith",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "David C.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-08-17",
        "review_rate": 4.8,
        "customer_name": "Cheryl Gray",
        "service_type": "Garage Door Insulation",
        "tech_name": "Debra J.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-12-14",
        "review_rate": 4.9,
        "customer_name": "Ronald Holland",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Kathy M.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-02-23",
        "review_rate": 4.9,
        "customer_name": "Mary Chavez",
        "service_type": "Garage Door Off Track",
        "tech_name": "Margaret B.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-02-10",
        "review_rate": 4.9,
        "customer_name": "Rachel Walls",
        "service_type": "Garage Door Opener",
        "tech_name": "Rachel G.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-09-08",
        "review_rate": 4.8,
        "customer_name": "Tiffany Harrington",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Briana B.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-03-18",
        "review_rate": 4.9,
        "customer_name": "Kimberly Bailey",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Joseph M.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-07-03",
        "review_rate": 5.0,
        "customer_name": "Evelyn Stephens",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Matthew V.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-12-05",
        "review_rate": 4.9,
        "customer_name": "Lisa Greene",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Christopher W.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-03-01",
        "review_rate": 4.8,
        "customer_name": "Timothy Wallace",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Aaron T.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-02-15",
        "review_rate": 5.0,
        "customer_name": "Melissa Williams MD",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Kenneth C.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-06-04",
        "review_rate": 4.8,
        "customer_name": "Hector Lane",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Jesse M.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-07-11",
        "review_rate": 4.9,
        "customer_name": "Erin Roberts",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Kendra T.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-06-14",
        "review_rate": 4.9,
        "customer_name": "Travis Cooper",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Kimberly D.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-04-07",
        "review_rate": 5.0,
        "customer_name": "Dustin Hughes",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Jason B.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-03-27",
        "review_rate": 4.9,
        "customer_name": "Francisco Howell",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Joel L.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-06-09",
        "review_rate": 4.9,
        "customer_name": "Patricia Roberts",
        "service_type": "Garage Door Insulation",
        "tech_name": "Ashley W.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-02-21",
        "review_rate": 4.8,
        "customer_name": "Brian Ross",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "David R.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-03-04",
        "review_rate": 4.9,
        "customer_name": "Rebecca Morgan",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Scott S.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-07-13",
        "review_rate": 5.0,
        "customer_name": "Juan Baker",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Nicole F.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-02-14",
        "review_rate": 5.0,
        "customer_name": "Keith Huff",
        "service_type": "Garage Door Installation",
        "tech_name": "Robert P.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-06-15",
        "review_rate": 4.9,
        "customer_name": "Kathryn Rasmussen",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Marissa P.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-04-07",
        "review_rate": 5.0,
        "customer_name": "Kimberly Hansen",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Tammy F.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-03-18",
        "review_rate": 4.9,
        "customer_name": "Robert Patterson",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Eric B.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-09-19",
        "review_rate": 4.9,
        "customer_name": "James Miller",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Allison B.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-06-25",
        "review_rate": 5.0,
        "customer_name": "Jennifer Bryant",
        "service_type": "Garage Door Opener",
        "tech_name": "Alexandra W.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-05-15",
        "review_rate": 4.9,
        "customer_name": "Mr. Chad Keller",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Cindy M.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-11-24",
        "review_rate": 4.8,
        "customer_name": "Larry Harris",
        "service_type": "Garage Door Opener",
        "tech_name": "Sara B.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-06-11",
        "review_rate": 4.8,
        "customer_name": "Sara Smith",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Billy H.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-02-29",
        "review_rate": 5.0,
        "customer_name": "Philip Wilkinson",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Patricia J.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-06-30",
        "review_rate": 4.8,
        "customer_name": "Dennis Bryant",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Anthony L.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-02-23",
        "review_rate": 4.8,
        "customer_name": "Patricia Ramirez",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Todd D.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-09-26",
        "review_rate": 4.9,
        "customer_name": "Derek Berry",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "John O.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-02-15",
        "review_rate": 4.9,
        "customer_name": "Brenda Rhodes",
        "service_type": "Garage Door Services",
        "tech_name": "Brandy M.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-01-19",
        "review_rate": 4.9,
        "customer_name": "Daniel Johnson",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Krista M.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-09-09",
        "review_rate": 4.9,
        "customer_name": "Taylor Smith",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Jose S.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-10-14",
        "review_rate": 4.9,
        "customer_name": "Troy Morton",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Jennifer L.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-08-30",
        "review_rate": 5.0,
        "customer_name": "Joanna Nguyen",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Nancy H.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-08-11",
        "review_rate": 4.9,
        "customer_name": "Anthony Lamb",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Christopher S.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-04-06",
        "review_rate": 4.9,
        "customer_name": "Aaron Aguilar DDS",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Sara M.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-08-20",
        "review_rate": 4.9,
        "customer_name": "Mrs. Maria Mitchell",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Raymond M.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-06-04",
        "review_rate": 4.9,
        "customer_name": "Cindy Goodwin",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Terri M.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-05-02",
        "review_rate": 4.9,
        "customer_name": "Edwin Miller",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Candice R.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-09-18",
        "review_rate": 4.9,
        "customer_name": "Charles Walls",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Nicholas M.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-10-14",
        "review_rate": 5.0,
        "customer_name": "Eric Mayer",
        "service_type": "Garage Door Insulation",
        "tech_name": "Louis I.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-02-10",
        "review_rate": 4.9,
        "customer_name": "Sharon Hayes",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Jack B.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-01-01",
        "review_rate": 4.8,
        "customer_name": "Andre Pope",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Mark G.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-01-03",
        "review_rate": 4.9,
        "customer_name": "Jeffrey Phillips",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Katrina H.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-09-13",
        "review_rate": 4.9,
        "customer_name": "Stephanie Williams",
        "service_type": "Garage Door Opener",
        "tech_name": "Richard M.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-01-29",
        "review_rate": 4.9,
        "customer_name": "Eric Maldonado",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Heather R.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-03-16",
        "review_rate": 4.8,
        "customer_name": "Caroline Gonzales",
        "service_type": "Garage Door Services",
        "tech_name": "Ann J.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-08-13",
        "review_rate": 4.9,
        "customer_name": "Andrew Parker",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Cory L.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-03-12",
        "review_rate": 4.8,
        "customer_name": "Garrett Bradford",
        "service_type": "Garage Door Opener",
        "tech_name": "Adam B.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-06-10",
        "review_rate": 4.9,
        "customer_name": "Mr. Curtis Rangel",
        "service_type": "Garage Door Off Track",
        "tech_name": "Brandon O.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-02-07",
        "review_rate": 4.9,
        "customer_name": "Jennifer Martinez",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Bridget A.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-04-11",
        "review_rate": 4.8,
        "customer_name": "Kenneth Bailey",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Susan L.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-11-25",
        "review_rate": 5.0,
        "customer_name": "Michele Price",
        "service_type": "Garage Door Repair",
        "tech_name": "Kayla B.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-03-30",
        "review_rate": 4.9,
        "customer_name": "Mark Meyer",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Elizabeth M.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-04-27",
        "review_rate": 4.9,
        "customer_name": "Maria Campbell",
        "service_type": "Garage Door Off Track",
        "tech_name": "Michael W.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-12-09",
        "review_rate": 4.9,
        "customer_name": "Victor Mahoney",
        "service_type": "Garage Door Insulation",
        "tech_name": "Sharon R.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-08-21",
        "review_rate": 4.8,
        "customer_name": "Amy Harris",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Bryan H.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-05-08",
        "review_rate": 5.0,
        "customer_name": "Theresa Green MD",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Joan H.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-07-11",
        "review_rate": 4.9,
        "customer_name": "Jennifer Mooney MD",
        "service_type": "Garage Door Off Track",
        "tech_name": "Douglas M.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-07-31",
        "review_rate": 4.9,
        "customer_name": "Michael Walker",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Richard W.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-09-28",
        "review_rate": 4.8,
        "customer_name": "Michelle Johnson",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Dylan V.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-01-20",
        "review_rate": 5.0,
        "customer_name": "Lindsey Johns",
        "service_type": "Garage Door Opener",
        "tech_name": "Michael C.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-01-05",
        "review_rate": 4.8,
        "customer_name": "Joseph Greer",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "John R.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-02-26",
        "review_rate": 4.8,
        "customer_name": "Nathan Hall",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Briana G.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-09-18",
        "review_rate": 4.8,
        "customer_name": "Scott Mullen",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "David M.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-03-23",
        "review_rate": 4.9,
        "customer_name": "Gina Williams",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Matthew C.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-07-01",
        "review_rate": 4.8,
        "customer_name": "Lisa Porter",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Nathan J.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-01-04",
        "review_rate": 4.9,
        "customer_name": "Gregory Lopez",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Jacob J.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-05-08",
        "review_rate": 4.9,
        "customer_name": "Tyler Brown",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Connie K.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-11-13",
        "review_rate": 4.9,
        "customer_name": "Lisa Beasley",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Anna B.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-01-29",
        "review_rate": 4.8,
        "customer_name": "Joanna Farrell",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Carlos P.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-10-20",
        "review_rate": 4.9,
        "customer_name": "Cindy Miller",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Jessica G.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-05-03",
        "review_rate": 4.9,
        "customer_name": "Daniel Johnson",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Scott H.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-06-14",
        "review_rate": 5.0,
        "customer_name": "Christopher Richards",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Jo B.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-12-06",
        "review_rate": 4.9,
        "customer_name": "Brittney Carter",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Charles T.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-12-30",
        "review_rate": 4.9,
        "customer_name": "Stephen Russell",
        "service_type": "Garage Door Repair",
        "tech_name": "Lauren M.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-07-02",
        "review_rate": 4.8,
        "customer_name": "Ricky Salas",
        "service_type": "Garage Door Opener",
        "tech_name": "James V.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-10-13",
        "review_rate": 4.9,
        "customer_name": "Samuel Rivera",
        "service_type": "Garage Door Off Track",
        "tech_name": "Scott H.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-07-02",
        "review_rate": 4.9,
        "customer_name": "Beverly Fisher",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Brianna W.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-09-19",
        "review_rate": 4.8,
        "customer_name": "Joanna Todd MD",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Marie P.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-03-31",
        "review_rate": 4.9,
        "customer_name": "Kenneth Hart",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Amy T.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-12-12",
        "review_rate": 5.0,
        "customer_name": "Nathan Stark",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Debra G.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-03-12",
        "review_rate": 4.9,
        "customer_name": "Sonia Gonzalez",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "George M.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-01-02",
        "review_rate": 4.9,
        "customer_name": "Miranda Orozco",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Julie R.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-05-24",
        "review_rate": 4.9,
        "customer_name": "Janet Robinson",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Jeffery H.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-11-30",
        "review_rate": 4.9,
        "customer_name": "Sarah Duncan",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Alexandra D.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-11-22",
        "review_rate": 4.9,
        "customer_name": "Danielle Brewer",
        "service_type": "Garage Door Installation",
        "tech_name": "Christine R.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-01-21",
        "review_rate": 4.9,
        "customer_name": "Stacey Rice",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Nicholas W.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-03-27",
        "review_rate": 4.8,
        "customer_name": "Vanessa Lopez",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Lawrence A.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-12-27",
        "review_rate": 4.8,
        "customer_name": "Ronald Wright",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Joel T.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-05-19",
        "review_rate": 4.9,
        "customer_name": "Beverly Adams",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Taylor A.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-02-04",
        "review_rate": 4.9,
        "customer_name": "Leonard Morales",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Joshua W.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-05-12",
        "review_rate": 4.9,
        "customer_name": "Seth Moore",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Deanna F.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-08-25",
        "review_rate": 5.0,
        "customer_name": "Roger Hampton",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Kevin S.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-07-24",
        "review_rate": 4.9,
        "customer_name": "Julie Duncan",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Michelle R.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-11-16",
        "review_rate": 4.9,
        "customer_name": "Hannah Williams",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Jade W.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-12-04",
        "review_rate": 4.9,
        "customer_name": "Whitney Simon",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Jeremy W.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-06-11",
        "review_rate": 4.9,
        "customer_name": "David Parrish",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Christina F.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-04-06",
        "review_rate": 5.0,
        "customer_name": "Angel Cole",
        "service_type": "Garage Door Opener",
        "tech_name": "Karen H.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-08-29",
        "review_rate": 4.8,
        "customer_name": "Scott Ward",
        "service_type": "Garage Door Installation",
        "tech_name": "Damon W.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-09-07",
        "review_rate": 4.8,
        "customer_name": "Colleen Elliott",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Martin B.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-01-17",
        "review_rate": 4.9,
        "customer_name": "Tiffany Ingram",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Meghan G.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-02-16",
        "review_rate": 4.9,
        "customer_name": "Alexis Erickson",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Robert B.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-02-09",
        "review_rate": 4.9,
        "customer_name": "Douglas Phillips",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Jeffrey J.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-06-19",
        "review_rate": 4.9,
        "customer_name": "Anne Hudson",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Chris L.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-06-25",
        "review_rate": 4.9,
        "customer_name": "Thomas Moore",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Kendra M.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-09-18",
        "review_rate": 4.8,
        "customer_name": "Kyle Ray",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Daniel H.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-09-08",
        "review_rate": 4.9,
        "customer_name": "Bethany Carroll",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Teresa P.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-02-08",
        "review_rate": 4.8,
        "customer_name": "Corey Myers",
        "service_type": "Garage Door Opener",
        "tech_name": "Rebecca R.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-05-01",
        "review_rate": 4.9,
        "customer_name": "Sheryl Hale",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Bryan W.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-08-16",
        "review_rate": 4.8,
        "customer_name": "Danielle Hale",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Paul H.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-12-14",
        "review_rate": 4.9,
        "customer_name": "Regina Odom",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Alicia M.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-12-18",
        "review_rate": 4.9,
        "customer_name": "Abigail Williams",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Lauren C.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-08-10",
        "review_rate": 4.8,
        "customer_name": "Lisa Jones MD",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Penny B.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-06-25",
        "review_rate": 4.8,
        "customer_name": "Debra Schultz",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Kristen T.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-09-17",
        "review_rate": 5.0,
        "customer_name": "Brian Robinson",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Brittany T.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-02-22",
        "review_rate": 4.9,
        "customer_name": "Jennifer Fields",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Sharon S.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-11-19",
        "review_rate": 4.8,
        "customer_name": "Mrs. Angela Myers DDS",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Erin M.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-12-10",
        "review_rate": 5.0,
        "customer_name": "Brett Little",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Vickie H.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-10-13",
        "review_rate": 4.9,
        "customer_name": "Patty Meyer",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Michelle M.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-08-14",
        "review_rate": 5.0,
        "customer_name": "Lisa Bell",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Jennifer R.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-07-07",
        "review_rate": 5.0,
        "customer_name": "Dr. Rebecca Greene",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Julia W.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-08-01",
        "review_rate": 4.8,
        "customer_name": "Haley Williams PhD",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Amy C.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-02-28",
        "review_rate": 4.9,
        "customer_name": "Dennis Blackwell",
        "service_type": "Garage Door Opener",
        "tech_name": "Maria J.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-10-21",
        "review_rate": 4.8,
        "customer_name": "Alex Sanchez",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Zachary C.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-10-17",
        "review_rate": 4.8,
        "customer_name": "Laura Carney",
        "service_type": "Garage Door Insulation",
        "tech_name": "Christine B.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-05-30",
        "review_rate": 4.9,
        "customer_name": "Jennifer Potts",
        "service_type": "Garage Door Installation",
        "tech_name": "Debbie S.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-06-03",
        "review_rate": 5.0,
        "customer_name": "James Mitchell",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Jessica H.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-02-19",
        "review_rate": 5.0,
        "customer_name": "Omar Bishop",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Jasmine S.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-11-22",
        "review_rate": 4.8,
        "customer_name": "Randy Gill",
        "service_type": "Garage Door Insulation",
        "tech_name": "Harold A.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-09-26",
        "review_rate": 4.9,
        "customer_name": "Jessica Jones",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Joann P.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-10-21",
        "review_rate": 4.8,
        "customer_name": "Robert Galloway",
        "service_type": "Garage Door Maintenance",
        "tech_name": "William G.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-08-21",
        "review_rate": 4.8,
        "customer_name": "Michelle Hernandez",
        "service_type": "Garage Door Installation",
        "tech_name": "Christopher C.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-07-01",
        "review_rate": 4.8,
        "customer_name": "Melissa Lopez",
        "service_type": "Garage Door Repair",
        "tech_name": "Amy C.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-11-08",
        "review_rate": 4.9,
        "customer_name": "Matthew Ryan",
        "service_type": "Garage Door Installation",
        "tech_name": "Cynthia K.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-09-26",
        "review_rate": 4.9,
        "customer_name": "Julie Murphy",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Claudia R.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-05-21",
        "review_rate": 4.8,
        "customer_name": "Anthony Mitchell",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Tracy L.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-06-08",
        "review_rate": 4.9,
        "customer_name": "John Benson",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Phillip B.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-03-25",
        "review_rate": 5.0,
        "customer_name": "Jim Greer",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Joan J.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-04-07",
        "review_rate": 4.9,
        "customer_name": "Michael Smith",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Thomas L.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-11-06",
        "review_rate": 4.9,
        "customer_name": "Jesse Graham",
        "service_type": "Garage Door Off Track",
        "tech_name": "Jessica E.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-05-30",
        "review_rate": 4.8,
        "customer_name": "Anna Rubio",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "James L.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-10-29",
        "review_rate": 5.0,
        "customer_name": "Roy Maynard",
        "service_type": "Garage Door Installation",
        "tech_name": "Ashley M.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-03-08",
        "review_rate": 4.8,
        "customer_name": "Phillip Cain",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Alyssa N.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-05-05",
        "review_rate": 4.9,
        "customer_name": "Michael Casey",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Rita S.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-04-03",
        "review_rate": 4.9,
        "customer_name": "Sarah Murphy",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Russell C.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-09-22",
        "review_rate": 5.0,
        "customer_name": "Troy Myers",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Kimberly C.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-04-11",
        "review_rate": 4.9,
        "customer_name": "Bryan Kemp",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Wayne G.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-08-28",
        "review_rate": 4.9,
        "customer_name": "Robert Freeman",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Gregory H.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-04-09",
        "review_rate": 4.8,
        "customer_name": "Lynn Terry",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Richard F.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-02-20",
        "review_rate": 4.8,
        "customer_name": "Randy Williams",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Connie V.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-06-20",
        "review_rate": 4.9,
        "customer_name": "Jessica Douglas",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Andrew F.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-01-07",
        "review_rate": 5.0,
        "customer_name": "Whitney Jackson",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Cody O.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-07-04",
        "review_rate": 4.9,
        "customer_name": "Rachel Lyons",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Joshua H.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-01-05",
        "review_rate": 4.9,
        "customer_name": "Mr. Charles Hurley",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Rebecca V.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-04-16",
        "review_rate": 5.0,
        "customer_name": "Rachael Simpson",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "David M.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-07-11",
        "review_rate": 4.8,
        "customer_name": "Kathy Johnson",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Jennifer H.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-05-14",
        "review_rate": 4.9,
        "customer_name": "Timothy Wise",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Andrew M.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-06-14",
        "review_rate": 4.8,
        "customer_name": "Miranda Cannon",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Nancy G.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-02-06",
        "review_rate": 4.9,
        "customer_name": "Austin Allen",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Lisa A.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-10-06",
        "review_rate": 4.9,
        "customer_name": "Diana Williams",
        "service_type": "Garage Door Off Track",
        "tech_name": "David S.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-04-05",
        "review_rate": 4.9,
        "customer_name": "Russell Leonard",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Brian M.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-05-13",
        "review_rate": 4.9,
        "customer_name": "Joshua Mcgrath",
        "service_type": "Garage Door Installation",
        "tech_name": "Christina R.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-07-03",
        "review_rate": 5.0,
        "customer_name": "Denise Sanchez",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Jessica B.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-10-19",
        "review_rate": 4.9,
        "customer_name": "Emma Dominguez",
        "service_type": "Garage Door Off Track",
        "tech_name": "Susan R.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-03-31",
        "review_rate": 4.9,
        "customer_name": "Lori Gordon",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Rachael T.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-03-29",
        "review_rate": 5.0,
        "customer_name": "Steven Bryan",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Melissa E.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-05-08",
        "review_rate": 4.9,
        "customer_name": "Nicholas Rollins",
        "service_type": "Garage Door Installation",
        "tech_name": "Jared C.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-08-19",
        "review_rate": 5.0,
        "customer_name": "Sarah Cantrell",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Nathan B.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-02-24",
        "review_rate": 5.0,
        "customer_name": "Joshua Beard",
        "service_type": "Garage Door Services",
        "tech_name": "Brittany C.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-08-25",
        "review_rate": 5.0,
        "customer_name": "Michael Leon",
        "service_type": "Garage Door Insulation",
        "tech_name": "Teresa H.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-11-03",
        "review_rate": 4.8,
        "customer_name": "Christy Parrish",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Lydia S.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-10-08",
        "review_rate": 4.9,
        "customer_name": "Stacey Barry",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Gregory B.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-11-28",
        "review_rate": 4.9,
        "customer_name": "Daniel Brown",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Katherine K.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-09-06",
        "review_rate": 4.9,
        "customer_name": "Gerald Harris",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Debra T.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-12-05",
        "review_rate": 4.9,
        "customer_name": "Tracy Gonzalez",
        "service_type": "Garage Door Maintenance",
        "tech_name": "James E.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-08-24",
        "review_rate": 5.0,
        "customer_name": "Miss Casey Marshall",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Emily G.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-11-03",
        "review_rate": 4.8,
        "customer_name": "Logan Thornton",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Anthony M.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-07-11",
        "review_rate": 4.9,
        "customer_name": "Samantha Lee",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Dana C.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-11-05",
        "review_rate": 4.9,
        "customer_name": "Tiffany Bell",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Randall D.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-07-17",
        "review_rate": 4.9,
        "customer_name": "Taylor Dunn",
        "service_type": "Garage Door Repair",
        "tech_name": "Sheri C.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-12-11",
        "review_rate": 5.0,
        "customer_name": "Tanner Lopez",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Jason T.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-03-16",
        "review_rate": 4.9,
        "customer_name": "Jaclyn Bennett",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Thomas J.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-07-28",
        "review_rate": 5.0,
        "customer_name": "Janice Dudley",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Sherry B.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-02-13",
        "review_rate": 4.9,
        "customer_name": "Jennifer Berger",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Terri E.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-05-08",
        "review_rate": 4.8,
        "customer_name": "Glenn Scott",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Elizabeth C.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-04-13",
        "review_rate": 4.9,
        "customer_name": "Bryan Martinez",
        "service_type": "Garage Door Off Track",
        "tech_name": "Stacy A.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-12-25",
        "review_rate": 4.8,
        "customer_name": "Patricia Mendoza",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Monica M.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-03-20",
        "review_rate": 4.8,
        "customer_name": "Lori Decker",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Patricia R.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-08-28",
        "review_rate": 4.9,
        "customer_name": "Joe Ward",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Gregory S.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-05-21",
        "review_rate": 4.9,
        "customer_name": "Peter Blake",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Joseph P.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-10-19",
        "review_rate": 4.9,
        "customer_name": "Patricia Murphy",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Matthew S.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-11-27",
        "review_rate": 5.0,
        "customer_name": "Daniel Duran",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Tracy R.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-04-28",
        "review_rate": 5.0,
        "customer_name": "Adam Howe",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Robert M.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-09-10",
        "review_rate": 5.0,
        "customer_name": "Stephanie Mooney",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Angela M.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-06-26",
        "review_rate": 4.9,
        "customer_name": "Stephanie Clark",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Kevin R.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-04-06",
        "review_rate": 4.9,
        "customer_name": "Benjamin Espinoza",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Christopher B.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-10-18",
        "review_rate": 4.8,
        "customer_name": "Heather Howell",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Renee T.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-01-16",
        "review_rate": 4.9,
        "customer_name": "Brandon Gonzalez",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Anthony M.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-01-02",
        "review_rate": 4.9,
        "customer_name": "Cory Gonzalez",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Denise C.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-02-17",
        "review_rate": 4.8,
        "customer_name": "Kelly Tapia",
        "service_type": "Garage Door Repair",
        "tech_name": "Wendy N.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-11-10",
        "review_rate": 4.8,
        "customer_name": "Dawn Butler",
        "service_type": "Garage Door Services",
        "tech_name": "Ryan P.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-08-03",
        "review_rate": 4.9,
        "customer_name": "Joy Hall",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Denise S.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-10-17",
        "review_rate": 4.9,
        "customer_name": "Jasmine Marquez",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Kathryn H.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-10-21",
        "review_rate": 4.9,
        "customer_name": "Chelsea Harvey",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Alicia S.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-06-16",
        "review_rate": 4.9,
        "customer_name": "David Bolton",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Amy K.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-01-27",
        "review_rate": 4.9,
        "customer_name": "Anthony Walters",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Vanessa W.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-10-07",
        "review_rate": 4.8,
        "customer_name": "Mr. Kenneth Wallace",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Lisa L.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-10-08",
        "review_rate": 4.9,
        "customer_name": "Holly Palmer",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Steven D.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-04-03",
        "review_rate": 4.9,
        "customer_name": "William Juarez",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Stephanie D.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-04-30",
        "review_rate": 4.9,
        "customer_name": "Andrea Manning",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Jaime W.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-09-05",
        "review_rate": 4.8,
        "customer_name": "Melissa Mccarthy",
        "service_type": "Garage Door Off Track",
        "tech_name": "Laura W.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-09-05",
        "review_rate": 5.0,
        "customer_name": "Jaime Clark",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Katherine A.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-02-09",
        "review_rate": 4.9,
        "customer_name": "Morgan Conley",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Lindsay L.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-09-23",
        "review_rate": 4.8,
        "customer_name": "Luke Miller",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "William S.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-04-24",
        "review_rate": 4.8,
        "customer_name": "Kimberly Navarro",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Christina M.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-10-06",
        "review_rate": 5.0,
        "customer_name": "Amanda Black",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Christina H.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-02-15",
        "review_rate": 4.9,
        "customer_name": "Kelly Garza",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Charles D.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-02-27",
        "review_rate": 4.9,
        "customer_name": "Jennifer Jones",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "John L.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-08-19",
        "review_rate": 4.9,
        "customer_name": "Robert Riley",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Zachary C.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-07-01",
        "review_rate": 4.9,
        "customer_name": "Brian Santos",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Charles W.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-11-19",
        "review_rate": 4.9,
        "customer_name": "Kurt Watkins",
        "service_type": "Garage Door Opener",
        "tech_name": "Heather L.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-08-04",
        "review_rate": 4.9,
        "customer_name": "Julia Paul",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Daniel B.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-11-09",
        "review_rate": 5.0,
        "customer_name": "Amy Wilson",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Andrea R.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-05-03",
        "review_rate": 4.9,
        "customer_name": "Rebecca Flores",
        "service_type": "Garage Door Installation",
        "tech_name": "Denise H.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-04-20",
        "review_rate": 4.8,
        "customer_name": "Sarah Conway",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Melissa B.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-04-26",
        "review_rate": 4.8,
        "customer_name": "Jeremy Castillo",
        "service_type": "Garage Door Repair",
        "tech_name": "Linda M.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-05-01",
        "review_rate": 4.8,
        "customer_name": "Martin White",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Kimberly J.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-08-17",
        "review_rate": 4.9,
        "customer_name": "Alexandra Adkins",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Angela E.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-11-05",
        "review_rate": 5.0,
        "customer_name": "Angela Burgess",
        "service_type": "Garage Door Off Track",
        "tech_name": "Andrew N.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-06-23",
        "review_rate": 4.9,
        "customer_name": "Katherine Clark",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Taylor B.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-08-12",
        "review_rate": 4.9,
        "customer_name": "James Fields",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Bradley L.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-07-27",
        "review_rate": 4.8,
        "customer_name": "Lisa Johnson",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Sandy B.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-05-17",
        "review_rate": 4.9,
        "customer_name": "Kelly Torres",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Nicole G.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-09-22",
        "review_rate": 4.9,
        "customer_name": "Jill Schultz",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Kelly K.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-05-22",
        "review_rate": 4.8,
        "customer_name": "Lindsey Chapman",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Sean J.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-11-04",
        "review_rate": 5.0,
        "customer_name": "Jonathan Frost",
        "service_type": "Garage Door Insulation",
        "tech_name": "Alexandra P.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-06-24",
        "review_rate": 4.9,
        "customer_name": "Jay Coleman",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Richard F.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-05-15",
        "review_rate": 4.9,
        "customer_name": "Robert Fowler",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Gregory D.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-03-18",
        "review_rate": 4.9,
        "customer_name": "Christopher Torres",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Patrick D.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-10-21",
        "review_rate": 4.8,
        "customer_name": "Alexander Roman",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Chad M.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-02-26",
        "review_rate": 4.8,
        "customer_name": "Ryan Johnson MD",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Adam L.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-01-06",
        "review_rate": 4.8,
        "customer_name": "Brad Mcpherson",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Sarah E.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-03-29",
        "review_rate": 5.0,
        "customer_name": "Richard Stone",
        "service_type": "Garage Door Insulation",
        "tech_name": "Ashley R.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-12-08",
        "review_rate": 4.9,
        "customer_name": "Kevin Ray",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Elizabeth W.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-10-14",
        "review_rate": 4.9,
        "customer_name": "Crystal Strong",
        "service_type": "Garage Door Insulation",
        "tech_name": "Jason C.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-02-26",
        "review_rate": 4.9,
        "customer_name": "Harold Williams",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Heather G.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-04-27",
        "review_rate": 4.9,
        "customer_name": "Holly Lee",
        "service_type": "Garage Door Services",
        "tech_name": "Susan J.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-03-30",
        "review_rate": 4.9,
        "customer_name": "Melissa Mills",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Daniel R.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-04-04",
        "review_rate": 4.9,
        "customer_name": "Michael Smith",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Desiree A.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-06-01",
        "review_rate": 4.9,
        "customer_name": "Anthony Fernandez",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Michael S.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-01-21",
        "review_rate": 4.8,
        "customer_name": "Brian White",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Jodi B.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-07-30",
        "review_rate": 4.9,
        "customer_name": "Angela Long",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Christopher C.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-04-25",
        "review_rate": 4.8,
        "customer_name": "Kevin Hill",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Johnny P.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-09-22",
        "review_rate": 5.0,
        "customer_name": "Michelle Wilson",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Robert S.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-04-29",
        "review_rate": 4.9,
        "customer_name": "Kim Goodman",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Lisa P.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-11-04",
        "review_rate": 4.8,
        "customer_name": "Richard Mcdaniel",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Bradley T.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-12-15",
        "review_rate": 4.8,
        "customer_name": "Bradley Gibson",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Robin K.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-07-03",
        "review_rate": 4.9,
        "customer_name": "Dr. Heather Blackburn",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Kyle D.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-12-10",
        "review_rate": 4.8,
        "customer_name": "Chris Baldwin",
        "service_type": "Garage Door Installation",
        "tech_name": "Melissa M.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-08-02",
        "review_rate": 4.9,
        "customer_name": "Mark Murphy",
        "service_type": "Garage Door Services",
        "tech_name": "David J.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-10-13",
        "review_rate": 4.9,
        "customer_name": "Kenneth Hardin",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Stacy T.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-12-21",
        "review_rate": 4.9,
        "customer_name": "Mrs. Heather Lopez",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Theresa C.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-04-03",
        "review_rate": 5.0,
        "customer_name": "Angel Meyers",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Randy C.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-08-02",
        "review_rate": 4.9,
        "customer_name": "James Garcia",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Lindsey H.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-02-13",
        "review_rate": 4.8,
        "customer_name": "Melody Edwards",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Dawn P.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-03-29",
        "review_rate": 4.9,
        "customer_name": "Mrs. Elizabeth Mitchell",
        "service_type": "Garage Door Opener",
        "tech_name": "Amber J.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-08-08",
        "review_rate": 4.8,
        "customer_name": "Gary Friedman",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Michelle C.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-01-25",
        "review_rate": 5.0,
        "customer_name": "Christy Wall",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Amy S.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-05-04",
        "review_rate": 4.9,
        "customer_name": "Nicholas Smith",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Elizabeth K.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-08-20",
        "review_rate": 5.0,
        "customer_name": "John Richardson",
        "service_type": "Garage Door Repair",
        "tech_name": "Jennifer R.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-04-18",
        "review_rate": 5.0,
        "customer_name": "Carol Mejia",
        "service_type": "Garage Door Services",
        "tech_name": "William M.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-02-03",
        "review_rate": 4.8,
        "customer_name": "Raymond Williams",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Beverly V.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-07-12",
        "review_rate": 4.9,
        "customer_name": "Amber Walker",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Maurice C.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-08-25",
        "review_rate": 4.9,
        "customer_name": "Benjamin Ross",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Jennifer M.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-11-18",
        "review_rate": 5.0,
        "customer_name": "Jennifer Stokes",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Tristan L.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-12-01",
        "review_rate": 5.0,
        "customer_name": "Charles Lewis",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Charles G.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-01-07",
        "review_rate": 4.9,
        "customer_name": "Jacob Frye",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Melissa W.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-03-26",
        "review_rate": 4.9,
        "customer_name": "Evelyn Jones",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Billy N.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-02-03",
        "review_rate": 5.0,
        "customer_name": "Nicholas Smith",
        "service_type": "Garage Door Repair",
        "tech_name": "Emma M.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-01-23",
        "review_rate": 4.9,
        "customer_name": "Malik Bell",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Melissa B.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-07-09",
        "review_rate": 4.9,
        "customer_name": "Justin Case",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Alexandra Y.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-09-05",
        "review_rate": 4.8,
        "customer_name": "Toni Stewart",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Cheyenne E.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-12-23",
        "review_rate": 5.0,
        "customer_name": "Rachel Williams",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Lisa B.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-03-04",
        "review_rate": 5.0,
        "customer_name": "Brandy Everett",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Elizabeth W.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-02-13",
        "review_rate": 4.9,
        "customer_name": "Dr. Renee Ritter",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Christina B.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-06-13",
        "review_rate": 4.8,
        "customer_name": "Alexandra Lawson",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Nicole M.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-03-25",
        "review_rate": 5.0,
        "customer_name": "Michael Baird",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Brenda C.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-01-11",
        "review_rate": 4.9,
        "customer_name": "Nathan Smith",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Brian R.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-04-13",
        "review_rate": 4.9,
        "customer_name": "Samantha Peters",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Stephen J.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-07-18",
        "review_rate": 5.0,
        "customer_name": "Tricia Osborne",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Jeffrey E.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-12-15",
        "review_rate": 4.9,
        "customer_name": "Danny Jackson",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Charles T.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-08-28",
        "review_rate": 4.8,
        "customer_name": "Brian Duffy DVM",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Robert M.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-06-04",
        "review_rate": 5.0,
        "customer_name": "Brian Jackson",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "David M.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-07-12",
        "review_rate": 4.9,
        "customer_name": "James Fisher",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Emily W.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-06-03",
        "review_rate": 5.0,
        "customer_name": "Caitlin Sanchez",
        "service_type": "Garage Door Insulation",
        "tech_name": "Jessica E.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-01-29",
        "review_rate": 4.8,
        "customer_name": "Mr. Joel Wilson DVM",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Mason H.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-10-18",
        "review_rate": 4.8,
        "customer_name": "George Stout",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Matthew L.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-10-17",
        "review_rate": 4.9,
        "customer_name": "Heather Cruz",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Laurie J.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-08-10",
        "review_rate": 4.9,
        "customer_name": "Andrew Moody",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Neil S.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-08-27",
        "review_rate": 4.9,
        "customer_name": "Adam Edwards",
        "service_type": "Garage Door Off Track",
        "tech_name": "Adam R.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-05-04",
        "review_rate": 4.9,
        "customer_name": "Justin Nichols",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Joan E.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-09-23",
        "review_rate": 5.0,
        "customer_name": "Jeffrey Hawkins",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Penny R.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-06-13",
        "review_rate": 5.0,
        "customer_name": "James Salinas",
        "service_type": "Garage Door Repair",
        "tech_name": "Kathryn G.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-11-16",
        "review_rate": 4.9,
        "customer_name": "Spencer Williams",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Leon B.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-06-15",
        "review_rate": 5.0,
        "customer_name": "Sarah Shaffer",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Francis P.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-10-05",
        "review_rate": 4.9,
        "customer_name": "Jonathon Huffman",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Kimberly G.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-01-26",
        "review_rate": 4.9,
        "customer_name": "Sara Duran",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Anna L.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-09-10",
        "review_rate": 4.9,
        "customer_name": "Patricia Rodriguez",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Samantha S.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-12-12",
        "review_rate": 4.9,
        "customer_name": "Kathryn Carter",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Daryl P.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-05-06",
        "review_rate": 4.9,
        "customer_name": "David James",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Michael D.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-06-05",
        "review_rate": 5.0,
        "customer_name": "Brian Burgess",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Isaac S.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-06-27",
        "review_rate": 5.0,
        "customer_name": "Angela Shea",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "James M.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-08-07",
        "review_rate": 4.9,
        "customer_name": "Debra Humphrey",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Lisa G.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-03-20",
        "review_rate": 5.0,
        "customer_name": "Heather Berry",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Nicholas B.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-07-15",
        "review_rate": 4.8,
        "customer_name": "Mary Scott",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Stephanie D.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-08-04",
        "review_rate": 4.8,
        "customer_name": "Elizabeth Williams",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "James W.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-12-20",
        "review_rate": 5.0,
        "customer_name": "Thomas Watson",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Todd F.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-02-26",
        "review_rate": 4.9,
        "customer_name": "Alyssa Ballard",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Justin G.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-01-01",
        "review_rate": 5.0,
        "customer_name": "Stephen Morton",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Oscar W.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-03-30",
        "review_rate": 4.8,
        "customer_name": "Yvonne Hoffman",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Scott L.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-05-28",
        "review_rate": 4.9,
        "customer_name": "Benjamin Tapia",
        "service_type": "Garage Door Off Track",
        "tech_name": "Allison W.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-01-24",
        "review_rate": 4.9,
        "customer_name": "Jessica Davis",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Amber A.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-04-01",
        "review_rate": 5.0,
        "customer_name": "Morgan Edwards",
        "service_type": "Garage Door Repair",
        "tech_name": "Elizabeth H.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-06-30",
        "review_rate": 5.0,
        "customer_name": "Marissa Murray",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Bruce W.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-11-20",
        "review_rate": 4.8,
        "customer_name": "Joshua Patton",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Gregory F.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-08-06",
        "review_rate": 4.9,
        "customer_name": "Michelle Moran",
        "service_type": "Garage Door Installation",
        "tech_name": "Thomas K.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-09-12",
        "review_rate": 5.0,
        "customer_name": "Melissa Leach",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Jessica N.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-04-19",
        "review_rate": 4.9,
        "customer_name": "Emily Roberson",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Casey M.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-07-21",
        "review_rate": 4.9,
        "customer_name": "Michelle James",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Nathan R.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-03-09",
        "review_rate": 4.9,
        "customer_name": "Christopher Vaughan",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Laurie S.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-07-17",
        "review_rate": 4.9,
        "customer_name": "Arthur Moses",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Matthew D.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-10-10",
        "review_rate": 4.8,
        "customer_name": "Victoria Adkins",
        "service_type": "Garage Door Installation",
        "tech_name": "Cindy K.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-11-20",
        "review_rate": 4.8,
        "customer_name": "Kyle Dyer",
        "service_type": "Garage Door Installation",
        "tech_name": "Albert C.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-11-02",
        "review_rate": 5.0,
        "customer_name": "Nancy Kennedy",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Kevin S.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-03-02",
        "review_rate": 4.9,
        "customer_name": "Brandon Hoffman",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Heather K.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-02-07",
        "review_rate": 4.9,
        "customer_name": "Arthur Rodriguez",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Matthew T.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-02-16",
        "review_rate": 4.9,
        "customer_name": "Alan Stafford",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Valerie J.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-04-10",
        "review_rate": 4.9,
        "customer_name": "Michael Mccarthy",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Lisa R.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-02-20",
        "review_rate": 4.9,
        "customer_name": "Sarah Lyons",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "William W.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-11-13",
        "review_rate": 4.9,
        "customer_name": "Mrs. Jessica Barnett MD",
        "service_type": "Garage Door Installation",
        "tech_name": "John C.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-05-29",
        "review_rate": 4.9,
        "customer_name": "Tanya Brown",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Christine R.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-09-02",
        "review_rate": 4.9,
        "customer_name": "Michael Johnston",
        "service_type": "Garage Door Installation",
        "tech_name": "Angel C.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-02-16",
        "review_rate": 4.9,
        "customer_name": "Jeremy Ball",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Charles S.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-02-23",
        "review_rate": 5.0,
        "customer_name": "Jay White",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Julia W.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-01-05",
        "review_rate": 4.9,
        "customer_name": "Kimberly Smith",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Robert S.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-11-03",
        "review_rate": 4.8,
        "customer_name": "Crystal Ward",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Ryan J.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-11-11",
        "review_rate": 4.9,
        "customer_name": "Peter Berry",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Deborah B.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-04-30",
        "review_rate": 4.8,
        "customer_name": "Henry Miller",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Aaron H.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-01-13",
        "review_rate": 4.9,
        "customer_name": "Joel Richardson",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Andrea M.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-06-19",
        "review_rate": 4.9,
        "customer_name": "Terri Herring",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Patrick R.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-11-05",
        "review_rate": 4.8,
        "customer_name": "Robert Acevedo",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "William H.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-08-26",
        "review_rate": 4.9,
        "customer_name": "Gabriel Salas",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Kathryn B.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-03-31",
        "review_rate": 5.0,
        "customer_name": "Mathew Martin",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Derek G.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-04-29",
        "review_rate": 4.8,
        "customer_name": "Matthew Durham",
        "service_type": "Garage Door Opener",
        "tech_name": "Mary F.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-10-11",
        "review_rate": 5.0,
        "customer_name": "Jose Washington",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Mark S.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-10-31",
        "review_rate": 4.8,
        "customer_name": "Darrell Wilson",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Sean S.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-09-07",
        "review_rate": 5.0,
        "customer_name": "Ryan Cherry",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Raymond D.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-10-08",
        "review_rate": 4.8,
        "customer_name": "Hannah Thomas",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Brenda Y.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-05-15",
        "review_rate": 4.9,
        "customer_name": "John Evans",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Donald M.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-08-06",
        "review_rate": 5.0,
        "customer_name": "Cindy Johnston",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Jason P.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-09-03",
        "review_rate": 4.9,
        "customer_name": "Ronald Dunlap",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Tammy H.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-07-31",
        "review_rate": 4.8,
        "customer_name": "Christina Lucas",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Jerry W.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-07-29",
        "review_rate": 5.0,
        "customer_name": "Allison Ward",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Allison C.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-01-26",
        "review_rate": 4.9,
        "customer_name": "Jennifer Ross",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Melissa P.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-02-03",
        "review_rate": 4.8,
        "customer_name": "Caitlin Warren",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Beth S.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-10-22",
        "review_rate": 4.9,
        "customer_name": "Jane Rios",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Patty H.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-11-01",
        "review_rate": 5.0,
        "customer_name": "Brian Jenkins",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Jean A.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-07-25",
        "review_rate": 4.8,
        "customer_name": "Alexander Boyd",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Brenda H.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-12-08",
        "review_rate": 4.9,
        "customer_name": "Kelly Vargas",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Steven S.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-04-12",
        "review_rate": 4.9,
        "customer_name": "Kendra Martin",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Gail P.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-11-20",
        "review_rate": 4.8,
        "customer_name": "Tony Baker",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Christina G.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-07-20",
        "review_rate": 4.8,
        "customer_name": "Nicole Lee",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Joe T.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-09-13",
        "review_rate": 4.8,
        "customer_name": "Kelly Mccullough",
        "service_type": "Garage Door Insulation",
        "tech_name": "Amanda B.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-06-26",
        "review_rate": 4.9,
        "customer_name": "Megan Harper",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Madeline P.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-04-10",
        "review_rate": 4.9,
        "customer_name": "David Ross",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Richard K.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-10-26",
        "review_rate": 4.8,
        "customer_name": "Christopher Smith",
        "service_type": "Garage Door Services",
        "tech_name": "Jamie O.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-11-07",
        "review_rate": 4.9,
        "customer_name": "Louis Scott",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Jesse B.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-10-20",
        "review_rate": 5.0,
        "customer_name": "Leslie Dorsey",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Ronald B.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-06-28",
        "review_rate": 4.9,
        "customer_name": "Nicole Williams",
        "service_type": "Garage Door Insulation",
        "tech_name": "Cassandra B.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-06-17",
        "review_rate": 4.8,
        "customer_name": "Samantha Moore",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Tiffany M.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-08-28",
        "review_rate": 4.9,
        "customer_name": "Ryan Simpson",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Karen K.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-12-17",
        "review_rate": 4.8,
        "customer_name": "Austin Bush",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Richard M.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-03-24",
        "review_rate": 4.9,
        "customer_name": "Jeffrey Liu",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Kimberly W.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-09-11",
        "review_rate": 4.9,
        "customer_name": "Ricky Wise",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Latasha D.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-06-18",
        "review_rate": 4.9,
        "customer_name": "Jennifer Gray",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Victoria H.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-08-15",
        "review_rate": 5.0,
        "customer_name": "Valerie Payne",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Karen R.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-06-20",
        "review_rate": 5.0,
        "customer_name": "Debra Mcgee",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Elizabeth S.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-06-24",
        "review_rate": 4.9,
        "customer_name": "Shelia Miller",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Christopher B.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-01-16",
        "review_rate": 4.8,
        "customer_name": "Jesse Edwards",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Leslie G.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-02-02",
        "review_rate": 4.8,
        "customer_name": "Christopher Parker",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Jonathan R.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-10-13",
        "review_rate": 4.9,
        "customer_name": "Katherine Burch",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Bryan G.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-10-28",
        "review_rate": 4.9,
        "customer_name": "Melissa Decker",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "James H.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-04-16",
        "review_rate": 4.9,
        "customer_name": "Bradley Smith",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Sarah G.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-09-09",
        "review_rate": 4.9,
        "customer_name": "Barbara Dixon",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Erica A.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-06-15",
        "review_rate": 4.9,
        "customer_name": "Gina White",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Pamela R.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-06-03",
        "review_rate": 4.8,
        "customer_name": "David Washington",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Lisa G.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-04-28",
        "review_rate": 4.9,
        "customer_name": "Adrian Johnson",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Melanie M.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-08-22",
        "review_rate": 5.0,
        "customer_name": "Jessica Rogers",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Sonya M.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-06-26",
        "review_rate": 4.9,
        "customer_name": "Katherine Moore",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Jeremy G.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-08-24",
        "review_rate": 4.9,
        "customer_name": "Matthew Smith",
        "service_type": "Garage Door Services",
        "tech_name": "Donna P.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-04-28",
        "review_rate": 4.9,
        "customer_name": "Stephanie Peterson",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Calvin M.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-06-20",
        "review_rate": 4.9,
        "customer_name": "Taylor Deleon",
        "service_type": "Garage Door Insulation",
        "tech_name": "Brian O.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-06-09",
        "review_rate": 4.9,
        "customer_name": "Roger Harris",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Craig W.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-02-20",
        "review_rate": 5.0,
        "customer_name": "Olivia Baird",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Jennifer R.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-01-30",
        "review_rate": 4.9,
        "customer_name": "Jennifer Elliott",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Jasmine P.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-09-03",
        "review_rate": 5.0,
        "customer_name": "Paul Estes",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Tracy S.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-04-15",
        "review_rate": 4.9,
        "customer_name": "Jason Sparks",
        "service_type": "Garage Door Off Track",
        "tech_name": "Bobby M.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-07-09",
        "review_rate": 4.8,
        "customer_name": "Maurice Wu",
        "service_type": "Garage Door Insulation",
        "tech_name": "Stephen S.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-09-04",
        "review_rate": 5.0,
        "customer_name": "Leon Green",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Frank M.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-11-17",
        "review_rate": 4.9,
        "customer_name": "Melinda Schneider",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Cory J.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-04-11",
        "review_rate": 5.0,
        "customer_name": "Rebecca Tucker",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Melissa F.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-01-16",
        "review_rate": 5.0,
        "customer_name": "Sarah Coleman",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Taylor A.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-02-02",
        "review_rate": 4.9,
        "customer_name": "Christopher Jones",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Melinda O.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-02-22",
        "review_rate": 5.0,
        "customer_name": "John Bird",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Alison C.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-08-22",
        "review_rate": 5.0,
        "customer_name": "Alexis Anderson",
        "service_type": "Garage Door Opener",
        "tech_name": "Chelsea S.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-04-20",
        "review_rate": 5.0,
        "customer_name": "Sandra Walker",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Frederick A.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-02-24",
        "review_rate": 4.9,
        "customer_name": "Danielle Carter",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Vincent S.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-03-21",
        "review_rate": 5.0,
        "customer_name": "Jared Hart",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Charles M.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-12-14",
        "review_rate": 4.9,
        "customer_name": "Jenna Walter",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Robert M.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-03-31",
        "review_rate": 4.8,
        "customer_name": "Christine Jennings",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Kelly S.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-10-04",
        "review_rate": 5.0,
        "customer_name": "Carol Ramirez",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Jeffrey R.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-10-06",
        "review_rate": 4.8,
        "customer_name": "Heather Montoya",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Mary K.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-03-06",
        "review_rate": 4.9,
        "customer_name": "Ms. Brittany Newton",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Caroline M.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-10-11",
        "review_rate": 5.0,
        "customer_name": "Sarah Lambert",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Andrew J.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-11-30",
        "review_rate": 4.9,
        "customer_name": "Sarah Hubbard",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Amy R.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-12-26",
        "review_rate": 5.0,
        "customer_name": "Zachary Campbell MD",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Angie B.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-09-06",
        "review_rate": 5.0,
        "customer_name": "Andrew Wood",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Samantha W.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-10-17",
        "review_rate": 4.9,
        "customer_name": "Ashley Alvarado",
        "service_type": "Garage Door Opener",
        "tech_name": "Clayton G.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-04-18",
        "review_rate": 4.9,
        "customer_name": "Mary Peterson",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Steven F.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-11-29",
        "review_rate": 4.9,
        "customer_name": "Dr. Alicia Mckay MD",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Clarence L.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-06-18",
        "review_rate": 4.8,
        "customer_name": "Angela Pacheco",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Laura J.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-06-21",
        "review_rate": 4.8,
        "customer_name": "Madison Sullivan",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Kristen O.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-12-06",
        "review_rate": 4.9,
        "customer_name": "Robert Porter",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Cameron J.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-01-28",
        "review_rate": 4.9,
        "customer_name": "Sydney Lane",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Jane W.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-08-21",
        "review_rate": 4.9,
        "customer_name": "Natasha Bradshaw",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Jonathan C.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-06-08",
        "review_rate": 4.9,
        "customer_name": "Alexander Lane",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Tammy W.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-06-28",
        "review_rate": 5.0,
        "customer_name": "Alexa Ward",
        "service_type": "Garage Door Opener",
        "tech_name": "Eric B.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-04-11",
        "review_rate": 5.0,
        "customer_name": "John Lopez",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Melissa K.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-03-19",
        "review_rate": 4.8,
        "customer_name": "Lauren Washington",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Taylor H.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-09-04",
        "review_rate": 4.8,
        "customer_name": "Shelly White",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Bryan S.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-05-26",
        "review_rate": 4.9,
        "customer_name": "Evan Blevins",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Mitchell G.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-08-18",
        "review_rate": 4.8,
        "customer_name": "Joy Chavez",
        "service_type": "Garage Door Insulation",
        "tech_name": "Lauren D.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-12-16",
        "review_rate": 4.9,
        "customer_name": "Austin Williams",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Paige K.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-01-21",
        "review_rate": 4.9,
        "customer_name": "Angela Mcmahon",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Gina P.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-08-08",
        "review_rate": 4.9,
        "customer_name": "Stephanie Fernandez",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "John P.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-04-22",
        "review_rate": 5.0,
        "customer_name": "Angel Gonzalez",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Jared G.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-11-25",
        "review_rate": 4.8,
        "customer_name": "Terry Craig",
        "service_type": "Garage Door Services",
        "tech_name": "Meghan R.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-03-07",
        "review_rate": 4.9,
        "customer_name": "Luke Young",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Tony R.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-07-09",
        "review_rate": 4.8,
        "customer_name": "Meghan Montgomery DDS",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Yolanda B.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-06-11",
        "review_rate": 4.9,
        "customer_name": "Charlotte Olson",
        "service_type": "Garage Door Off Track",
        "tech_name": "Donald H.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-12-26",
        "review_rate": 4.9,
        "customer_name": "Kristopher Hayes",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "John D.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-02-23",
        "review_rate": 4.9,
        "customer_name": "Sheila Dunlap",
        "service_type": "Garage Door Repair",
        "tech_name": "Debra P.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-12-19",
        "review_rate": 4.9,
        "customer_name": "Christian Beard",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Jenna W.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-03-14",
        "review_rate": 4.9,
        "customer_name": "David Williams",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Rhonda D.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-04-26",
        "review_rate": 5.0,
        "customer_name": "Cameron Gibbs",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Sherri B.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-08-19",
        "review_rate": 4.9,
        "customer_name": "Denise Summers",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Ryan M.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-08-25",
        "review_rate": 5.0,
        "customer_name": "Megan Roberts",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Colleen B.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-08-27",
        "review_rate": 5.0,
        "customer_name": "Lynn Crane",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Andrea T.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-10-08",
        "review_rate": 4.9,
        "customer_name": "David Irwin",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "William S.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-02-12",
        "review_rate": 5.0,
        "customer_name": "Karen Steele",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Chelsey F.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-11-30",
        "review_rate": 4.8,
        "customer_name": "Nicholas Gilmore MD",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Andrew N.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-05-21",
        "review_rate": 5.0,
        "customer_name": "Charlotte Wood",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Derek R.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-06-08",
        "review_rate": 4.8,
        "customer_name": "John Smith",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Nancy G.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-08-13",
        "review_rate": 4.9,
        "customer_name": "Frederick Foster",
        "service_type": "Garage Door Track Repair",
        "tech_name": "George J.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-02-20",
        "review_rate": 4.8,
        "customer_name": "Mario King",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Amy W.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-05-06",
        "review_rate": 4.8,
        "customer_name": "Amanda Young",
        "service_type": "Garage Door Insulation",
        "tech_name": "Judith W.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-08-27",
        "review_rate": 5.0,
        "customer_name": "Katelyn Lynch",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Jill H.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-02-01",
        "review_rate": 4.8,
        "customer_name": "Anna Robinson",
        "service_type": "Garage Door Insulation",
        "tech_name": "Susan T.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-07-24",
        "review_rate": 4.9,
        "customer_name": "Michael Campbell",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Robert S.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-10-27",
        "review_rate": 5.0,
        "customer_name": "Stacey Sutton",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Sarah J.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-04-12",
        "review_rate": 5.0,
        "customer_name": "Tiffany Waters",
        "service_type": "Garage Door Insulation",
        "tech_name": "Alexander L.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-12-19",
        "review_rate": 4.9,
        "customer_name": "Barbara Miller",
        "service_type": "Garage Door Services",
        "tech_name": "Christopher W.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-07-21",
        "review_rate": 5.0,
        "customer_name": "Jamie Williams",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Michael T.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-01-13",
        "review_rate": 4.9,
        "customer_name": "David Miller",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Leslie B.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-02-13",
        "review_rate": 4.9,
        "customer_name": "Margaret Brown",
        "service_type": "Garage Door Insulation",
        "tech_name": "Holly P.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-10-18",
        "review_rate": 5.0,
        "customer_name": "Emily Ferguson",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Katherine T.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-06-16",
        "review_rate": 4.8,
        "customer_name": "Isabella Powell",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Tracy L.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-01-21",
        "review_rate": 4.8,
        "customer_name": "Jeremy Price",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Katelyn J.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-06-14",
        "review_rate": 4.9,
        "customer_name": "Anna Pollard",
        "service_type": "Garage Door Off Track",
        "tech_name": "Rebecca A.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-11-04",
        "review_rate": 4.9,
        "customer_name": "Tyrone Hartman",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Michael S.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-07-07",
        "review_rate": 4.9,
        "customer_name": "Crystal Williams",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "James R.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-06-04",
        "review_rate": 4.9,
        "customer_name": "Alexis Reese",
        "service_type": "Garage Door Insulation",
        "tech_name": "Anthony F.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-07-14",
        "review_rate": 5.0,
        "customer_name": "Casey Patterson",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Tammy R.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-11-22",
        "review_rate": 5.0,
        "customer_name": "Brandi Wang",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Christopher H.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-09-25",
        "review_rate": 5.0,
        "customer_name": "Eugene Young",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Larry G.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-01-31",
        "review_rate": 5.0,
        "customer_name": "Kerry Nicholson",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Brenda D.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-02-02",
        "review_rate": 5.0,
        "customer_name": "Sherri Weaver",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Kylie C.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-04-16",
        "review_rate": 4.9,
        "customer_name": "Melvin Carrillo",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Karen W.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-12-21",
        "review_rate": 4.9,
        "customer_name": "Paul Melendez",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Whitney L.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-12-25",
        "review_rate": 5.0,
        "customer_name": "Joseph Koch",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Sandra R.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-05-24",
        "review_rate": 4.9,
        "customer_name": "Stephen Vega",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Joel S.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-07-22",
        "review_rate": 4.9,
        "customer_name": "Elizabeth Wells",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Brandon C.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-02-11",
        "review_rate": 4.8,
        "customer_name": "Kristi Berry",
        "service_type": "Garage Door Services",
        "tech_name": "Kristen B.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-08-31",
        "review_rate": 5.0,
        "customer_name": "Michael Robinson",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Cheryl C.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-07-01",
        "review_rate": 4.9,
        "customer_name": "Olivia Salazar DDS",
        "service_type": "Garage Door Opener",
        "tech_name": "Amanda C.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-06-17",
        "review_rate": 5.0,
        "customer_name": "Carla Jones",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Sarah C.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-03-17",
        "review_rate": 5.0,
        "customer_name": "Alexander Pope",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Karen P.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-04-08",
        "review_rate": 5.0,
        "customer_name": "Stacy Horton",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Randall A.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-03-05",
        "review_rate": 5.0,
        "customer_name": "Amy Acosta",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Luke W.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-07-17",
        "review_rate": 4.9,
        "customer_name": "Mark Hobbs",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Christopher D.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-09-24",
        "review_rate": 4.9,
        "customer_name": "Andrew Wood",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Todd S.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-11-08",
        "review_rate": 4.8,
        "customer_name": "Samantha Williams",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Susan M.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-05-23",
        "review_rate": 4.8,
        "customer_name": "Eric Moore",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Jenna R.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-07-25",
        "review_rate": 4.8,
        "customer_name": "Mark Thomas",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Christopher M.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-11-06",
        "review_rate": 5.0,
        "customer_name": "Ryan Romero",
        "service_type": "Garage Door Off Track",
        "tech_name": "George E.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-04-17",
        "review_rate": 4.9,
        "customer_name": "Julia Bridges",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Mark P.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-10-30",
        "review_rate": 4.8,
        "customer_name": "Aaron Pollard",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Meghan S.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-11-04",
        "review_rate": 4.8,
        "customer_name": "Cynthia Robinson",
        "service_type": "Garage Door Insulation",
        "tech_name": "Suzanne S.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-12-01",
        "review_rate": 4.8,
        "customer_name": "Jason Chambers",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Jennifer G.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-04-08",
        "review_rate": 4.9,
        "customer_name": "Andrew Hanson",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Reginald W.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-12-22",
        "review_rate": 4.9,
        "customer_name": "Amy Frank",
        "service_type": "Garage Door Installation",
        "tech_name": "Cory B.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-11-27",
        "review_rate": 5.0,
        "customer_name": "Debra Delgado",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Louis F.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-03-30",
        "review_rate": 4.9,
        "customer_name": "Sharon Smith",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Hannah K.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-06-11",
        "review_rate": 4.9,
        "customer_name": "Donald Rivas",
        "service_type": "Garage Door Insulation",
        "tech_name": "Chase A.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-03-20",
        "review_rate": 5.0,
        "customer_name": "Anthony Payne",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Frank M.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-10-08",
        "review_rate": 4.9,
        "customer_name": "Jenna Dyer",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Amy B.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-08-31",
        "review_rate": 4.9,
        "customer_name": "Don Flores",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Vicki H.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    }
]
export default LocationReviewsData;
